function PLPEvents(tagData) {
    const {
        categoryName,
        currencyCode,
        productsDetails = [],
        type,
        category
    } = tagData;
    
    let processedData = {
        event: type ? type : 'category',
        category: categoryName,
        category_id: category?.br_c_id ?? '',
        brand: 'ELEMIS',
        category_level_1: category?.br_c_level_1 ?? '',
        category_level_2: category?.br_c_level_2 ?? '',
        category_level_3: category?.br_c_level_3 ?? '',
        categories_path: category?.br_c_path ?? '',
        categories_ids: category?.br_c_ids
            ? JSON.parse(category?.br_c_ids)
            : [],
        domain: window.location.hostname,
        location: window.location.origin + window.location.pathname,
        _clear: true
    };
    
    if (productsDetails.length > 0) {
        processedData['ecommerce'] = {
            currencyCode,
            impressions: productsDetails
        };
    }

    return processedData;
}
export { PLPEvents };
