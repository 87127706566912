/**
 * Cannot use useScript hook since script has to be
 * loaded on demnand. prevents using inside an hook
 * @param url
 * @param attributes
 */
const injectScipt = (url, attributes) => {
    if (!url) return;

    let script = document.querySelector(`script[src="${url}"]`);

    if (!script) {
        script = document.createElement('script');
        script.src = url;
        script.defer = true;

        //merge the custom attibutes
        if (Object.entries(attributes).length !== 0) {
            for (var key in attributes) {
                script.setAttribute(key, attributes[key]);
            }
        }

        document.head.appendChild(script);

        script.onerror = () => {
            if (script) console.warn('Error loading signifyd script');
        };
    }
};

/**
 * Remove the script using some identifier
 * @param url
 */
const removeScript = identifier => {
    if (!identifier) return;
    let script = document.querySelector(`script[${identifier}]`);

    if (script) {
        document.head.removeChild(script);
    }
};

/**
 * Check if signified has already loaded
 * @return {*}
 */
const hasSignifyedLoaded = () => {
    return (
        typeof SIGNIFYD_GLOBAL !== 'undefined' &&
        SIGNIFYD_GLOBAL &&
        SIGNIFYD_GLOBAL.scriptTagHasLoaded()
    );
};

/**
 * reset the signified profiling as a fix to
 * regerate the script and iframe injections
 */
const resetSinifiedProfiling = () => {
    if (typeof tmx_profiling_started !== 'undefined' && tmx_profiling_started)
        tmx_profiling_started = false;
};

/**
 * Reset singnifed injection on cart | quote change
 */
const resetSignifyed = url => {
    if (hasSignifyedLoaded()) {
        let signifyedIframe = document.querySelector(
            `iframe[id="tmx_tags_iframe"]`
        );
        resetSinifiedProfiling();
        removeScript(`src="${url}"`);
        removeScript(`data-id="signifyd-tmx"`);
        if (signifyedIframe) signifyedIframe.remove();
    }
};

export const signifyedHelper = {
    injectScipt,
    removeScript,
    hasSignifyedLoaded,
    resetSinifiedProfiling,
    resetSignifyed
};
