function CheckoutEvents(tagData) {
    const { actionField, productsDetails = [] } = tagData;

    let productIds = tagData.cart?.items?.map(item =>
        item.product.sku ? item.product.sku : item.itemsku
    );
    let variantIds = tagData.cart?.items?.map(item => item.itemsku);

    let productList = tagData.cart?.items?.map(item => ({
        product_id: item.product.sku ? item.product.sku : item.itemsku,
        quantity: item.quantity
    }));

    let variantList = tagData.cart?.items?.map(item => ({
        product_id: item.itemsku ? item.itemsku : item.parent_sku,
        quantity: item.quantity
    }));

    let ecommerce = {
        checkout: {
            actionField: {
                step: actionField.step
            },
            step_title:
                actionField?.description === 'payment'
                    ? 'review & payment'
                    : actionField?.description,
            domain: window.location.hostname,
            product_list: productList,
            product_ids: productIds,
            variant_list: variantList,
            variant_ids: variantIds,
            total_purchase_value:
                tagData.cart.prices?.subtotal_including_tax?.value,
            total_purchase_value_without_tax:
                tagData.cart.prices?.subtotal_with_discount_excluding_tax
                    ?.value,
            shipping_cost:
                tagData.cart?.shipping_addresses[0]?.selected_shipping_method
                    ?.amount?.value,
            total_tax_value:
                tagData.cart?.prices?.applied_taxes[0]?.amount?.value,
            local_currency: tagData.currency,
            total_quantity: tagData?.cart?.total_quantity,
            ...(actionField.step == 1 ? { products: productsDetails } : {})
        }
    };

    return {
        event: 'checkout',
        ecommerce: ecommerce,
        _clear: true
    };
}
export { CheckoutEvents };
