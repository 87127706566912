import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartStore } from '@corratech/context-provider';
import { getCurrencySymbol } from '../util/getCurrencySymbol';

const RenderAppliedCredits = ({ className = '' }) => {
    const { cartState } = useContext(CartStore);
    const { applied_gift_cards: giftCards, applied_store_credit: storeCreditData } = cartState.cart;

    if (!giftCards?.length && !storeCreditData?.applied_balance?.value) return null;

    return (
        <div className={`render-applied-credits${className ? ` ${className}` : ''}`}>
            {giftCards?.length > 0 && <RenderGiftCardData giftCards={giftCards} />}
            {storeCreditData?.applied_balance?.value > 0 && <RenderStoreCreditData storeCreditData={storeCreditData} />}
        </div>
    );
};

const RenderGiftCardData = ({ giftCards }) => {
    const [t] = useTranslation();

    const totalBalance = giftCards.reduce((sum, card) => sum + card.applied_balance.value, 0);

    if (totalBalance > 0) {
        return (
            <div className="applied-gc-balance" style={{ marginBottom: 10 }}>
                {t('Applied')}{' '}
                {getCurrencySymbol(giftCards[0].applied_balance.currency)}
                {totalBalance.toFixed(2)}{' '}
                {giftCards.length === 1
                    ? t('from a gift card to your order.')
                    : t('from gift cards to your order.')}
            </div>
        );
    }

    return null;
};

const RenderStoreCreditData = ({ storeCreditData }) => {
    const [t] = useTranslation();

    const { applied_balance: appliedBalance } = storeCreditData;

    return (
        <div className="applied-sc-balance">
            {t('Applied')}{' '}
            {getCurrencySymbol(appliedBalance.currency)}
            {appliedBalance.value.toFixed(2)}{' '}
            {t('from store credit to your order.')}
        </div>
    );
};

export { RenderAppliedCredits, RenderGiftCardData, RenderStoreCreditData };
