import { mutationCreateNewsSubscriber } from "@corratech/checkout/graphql/mutationCreateNewsSubscriber";
import React, { useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { CartStore } from '@corratech/context-provider';
import { useMutation } from 'react-apollo';
import MUTATION_SET_GUEST_EMAIL_ON_CART from '@corratech/checkout/forms/CheckoutAccountForm/graphql/MUTATION_SET_GUEST_EMAIL_ON_CART.JS';
import { useHistory } from 'react-router-dom';
import { isSignedIn } from '@corratech/context-provider';
import { CheckoutAgreements } from '@corratech/checkout/CheckoutAgreements/CheckoutAgreements';
import NewsletterCheckbox from '@corratech/checkout/NewsletterCheckbox/NewsletterCheckbox';
import { PrivacyPolicy } from 'ModulesPath/Checkout';

export const PayPalPlaceOrderButton = props => {
    const { price, isGiftCardProduct } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const [shippingMethodSelected, setShippingMethodSelected] = useState(false);
    const [newsletterChecked, setNewsletterChecked] = useState();
    const [agreementsChecked, setAgreementsChecked] = useState(false);
    const redirect = useHistory();

    const [createNewsSubscriber] = useMutation(mutationCreateNewsSubscriber, {
        onError: res => {
            console.warn(res);
        }
    });

    const placeOrderPayPalUpdate = () => {
        if (newsletterChecked) {
            createNewsSubscriber({
                variables: { subscriberEmail: cartState.cart.email, type: 'checkout' }
            });
        }

        redirect.push('/checkout/paypal/success/' + window.location.search);
    };

    useEffect(() => {
        if (
            cartState.cart.shipping_addresses[0] &&
            cartState.cart.shipping_addresses[0].selected_shipping_method !==
                undefined &&
            cartState.cart.shipping_addresses[0].selected_shipping_method !==
                null
        ) {
            setShippingMethodSelected(true);
        } else {
            setShippingMethodSelected(false);
        }
    }, [
        cartState.cart.shipping_addresses[0] &&
            cartState.cart.shipping_addresses[0].selected_shipping_method
    ]);

    return (
        <>
            {isGiftCardProduct && (
                <>
                    <CheckoutAgreements
                        parentChecked={agreementsChecked}
                        setParentChecked={setAgreementsChecked}
                    />
                    <NewsletterCheckbox />
                    <PrivacyPolicy privacyPolicyLink={'/privacy-policy'} />
                    <div className="pay-pal-button" id="PayPalPayButton">
                        <Button
                            onClick={() => placeOrderPayPalUpdate()}
                            disabled={!isGiftCardProduct}
                        >
                            Place Order
                        </Button>
                    </div>
                </>
            )}
            {!isGiftCardProduct && shippingMethodSelected && (
                <>
                    <CheckoutAgreements
                        parentChecked={agreementsChecked}
                        setParentChecked={setAgreementsChecked}
                    />
                    <NewsletterCheckbox newsletterChecked={newsletterChecked} setNewsletterChecked={setNewsletterChecked} />
                    <PrivacyPolicy privacyPolicyLink={'/privacy-policy'} />
                    <div className="pay-pal-button" id="PayPalPayButton">
                        <Button
                            onClick={() => placeOrderPayPalUpdate()}
                            disabled={!shippingMethodSelected}
                        >
                            Place Order
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

PayPalPlaceOrderButton.propTypes = {
    cartId: PropTypes.string.isRequired
};
