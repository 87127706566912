import React from 'react';

export const CardIcon = () => {
    return (
        <svg role="img" aria-label="Card Icon" width="36" height="24" viewBox="0 0 36 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="23" rx="1.5" fill="none" stroke="#E6E9EB"/>
            <path
                d="M28 4C29.1046 4 30 4.89543 30 6V18C30 19.1046 29.1046 20 28 20H8C6.89543 20 6 19.1046 6 18V6C6 4.89543 6.89543 4 8 4H28ZM29 11H7V18C7 18.5128 7.38604 18.9355 7.88338 18.9933L8 19H28C28.5128 19 28.9355 18.614 28.9933 18.1166L29 18V11ZM20 16V17H9V16H20ZM25 16V17H22V16H25ZM28 5H8C7.48716 5 7.06449 5.38604 7.00673 5.88338L7 6V8H29V6C29 5.48716 28.614 5.06449 28.1166 5.00673L28 5Z"
                fill="#002739"/>
        </svg>
    )
};