import React, { useRef, useState, useEffect } from 'react';
import { Checkbox } from '@corratech/form-components';
import { useTranslation } from 'react-i18next';

const OGSubscriptionCheckBox = props => {
    const [t] = useTranslation();
    const refCheckbox = useRef();
    const ogSubscribeId = useRef(`og-subscribe-${Math.random().toString(36).substr(2, 9)}`).current;
    const { ogSubscribeChecked, setOGSubscribeChecked, setShouldSaveCard = null, shouldSaveCard = false} = props;

    const handleChange = () => {
        if (refCheckbox.current.checked) {
            setOGSubscribeChecked(true);
            if (setShouldSaveCard !== null && !shouldSaveCard) {
                setShouldSaveCard(true);
            }
        } else {
            setOGSubscribeChecked(false);
        }
    };

    return (
        <span className={'newsletter-checkbox'}>
            <Checkbox
                ref={refCheckbox}
                field="og_subscriber"
                onChange={() => handleChange()}
                id={ogSubscribeId}
                label={
                    <span>
                        {t(
                            `I agree to allow ELEMIS to store my credit card data for recurring subscription orders.`
                        )}
                    </span>
                }
                fieldState={{ value: ogSubscribeChecked }}
            />
        </span>
    );
};

export default OGSubscriptionCheckBox;
