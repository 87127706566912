import { mutationCreateNewsSubscriber } from "@corratech/checkout/graphql/mutationCreateNewsSubscriber";
import React, { useContext, useState } from 'react';
import { useMutation } from "react-apollo";
import { Button } from 'react-bootstrap';
import { AuthStore, CartStore, LoaderStore } from '@corratech/context-provider';
import { AuthBillingAddressForm } from '@corratech/checkout/PaymentMethods/BraintreePayment/AuthBillingAddressForm';
import { UnAuthBillingAddressForm } from '@corratech/checkout/PaymentMethods/BraintreePayment/UnAuthBillingAddressForm';
import { isAddressValid } from '@corratech/checkout/util/isAddressValid';
import { deepValue } from '@corratech/tag-manager';

export const PlaceOrder = props => {
    const {
        buttonState,
        setKlarnaPayment,
        handlePlaceOrder,
        klarnaType,
        getBillingAddress,
        getShippingAddress,
        setPaymentProcessing,
        getCartItems,
        PrivacyPolicy,
        privacyPolicyLink,
        paymentProcessing,
        NewsletterCheckbox
    } = props;

    const { authState } = useContext(AuthStore);
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [newsletterChecked, setNewsletterChecked] = useState();

    /**
     * Update klarna suthorise token to magento quote and trigger place order 
     * @param {*} authToken 
     * @param {*} cartResponse 
     */
    const updateKlarnaPayment = (authToken) => {
        setPaymentProcessing(true);
        setKlarnaPayment({
            variables: {
                "authorization_token": authToken,
                "paymentMethodCode": `klarna_${klarnaType}`
            }
        }).then(res => {
            dispatch({
                type: 'SET_CART',
                cart: res.data.setPaymentMethodOnCart.cart
            });
            handlePlaceOrder();
        }).catch(e => {
            console.warn(e);
            setPaymentProcessing(false);
        });

    }

    const [createNewsSubscriber] = useMutation(mutationCreateNewsSubscriber, {
        onError: res => {
            console.warn(res);
        }
    });

    const authorizeKlarnaPayment = () => {
        const cartPrices = deepValue(cartState, ['cart', 'prices'], []);
        Klarna.Payments.authorize({
            payment_method_category: klarnaType
        }, {
            purchase_country: window.STORE_CODE == 'uk' ? 'GB' : 'US',
            purchase_currency: deepValue(cartPrices, ['grand_total', 'currency'], 'GBP'),
            locale: window.STORE_CODE == 'uk' ? 'en-GB' : 'en-US',
            billing_address: getBillingAddress(),
            shipping_address: getShippingAddress(),
            order_amount: Math.round(deepValue(cartPrices, ['grand_total', 'value'], 0) * 100),
            order_lines: getCartItems()
        }, function (res) {
            if (res.approved && res.authorization_token && res.show_form) {
                if (res.finalize_required) {
                    Klarna.Payments.finalize(
                        { payment_method_category: klarnaType },
                        {},
                        function (response) {
                            if (response.approved && response.authorization_token && response.show_form) {
                                updateKlarnaPayment(response.authorization_token);
                            }
                        });

                    if (newsletterChecked) {
                        createNewsSubscriber({
                            variables: { subscriberEmail: cartState.cart.email, type: 'checkout' }
                        });
                    }
                } else {
                    updateKlarnaPayment(res.authorization_token);
                }
            }
        });
    }

    return (
        <>
            {authState.token && authState.token !== '' ? (
                <AuthBillingAddressForm />
            ) : (
                    <UnAuthBillingAddressForm />
                )}
            {NewsletterCheckbox ? <NewsletterCheckbox newsletterChecked={newsletterChecked} setNewsletterChecked={setNewsletterChecked} /> : ''}
            {props.PrivacyPolicy ? <PrivacyPolicy
                privacyPolicyLink={
                    privacyPolicyLink
                }
            /> : ''}
            {paymentProcessing ? (<LoadingIndicator />) :
                (<Button
                    className={'submit-klarna-method'}
                    disabled={
                        buttonState.disabled ||
                        (!cartState.cart.isBillingSameAsShipping &&
                            !isAddressValid(cartState.cart.billing_address))
                    }
                    onClick={async () => {
                        authorizeKlarnaPayment();
                    }}
                >
                    {buttonState.text}
                </Button>)}
        </>
    );
}
export default PlaceOrder;