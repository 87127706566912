import React, { useRef } from 'react';
import { Checkbox } from '@corratech/form-components';
import { useTranslation } from 'react-i18next';

const NewsletterCheckbox = props => {
    const [t] = useTranslation();
    const refCheckbox = useRef();
    const { newsletterChecked, setNewsletterChecked } = props;
    const coNewsletterSubscribeId = useRef(`co-newsletter-subscribe-${Math.random().toString(36).substr(2, 9)}`).current;

    return (
        <>
            <span className={'newsletter-checkbox'}>
                <Checkbox
                    ref={refCheckbox}
                    field="news_letter_subscriber"
                    onChange={() => setNewsletterChecked(refCheckbox.current.checked)}
                    id={coNewsletterSubscribeId}
                    label={
                        <span>
                            {t(
                                `I agree to receive email communications from ELEMIS.`
                            )}
                        </span>
                    }
                    fieldState={{ value: newsletterChecked }}
                />
            </span>
        </>
    );
};

export default NewsletterCheckbox;
