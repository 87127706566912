import React, { useEffect, useState, useContext } from 'react';
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import ConstructorRecommendationItem from 'ModulesPath/ConstructorRecommendation/ConstructorRecommendationItem';
import { useConstructorConfig } from 'ModulesPath/SearchBar/useConstructorConfig';
import './ConstructorRecommendation.less';
import Slider from 'react-slick';
import jsSHA from 'jssha/sha256';
import { AuthStore, isSignedIn } from '@corratech/context-provider';
import { LoaderStore } from '@corratech/context-provider';
import {useTranslation} from "react-i18next";

/**
 * Slider Next Icon
 */
const NextIcon = props => {
    const { className, style, onClick, label } = props;
    const tabIndex = className.includes('slick-disabled') ? -1 : 0;

    return (
        <button className={className} style={{ ...style }} onClick={onClick} tabIndex={tabIndex}>
            {label}
        </button>
    );
};

/**
 * Slider Previous Icon
 */
const PrevIcon = props => {
    const { className, style, onClick, label } = props;
    const tabIndex = className.includes('slick-disabled') ? -1 : 0;

    return (
        <button className={className} style={{ ...style }} onClick={onClick} tabIndex={tabIndex}>
            {label}
        </button>
    );
};

export const ConstructorRecommendation = ({
    pod_id,
    itemsId,
    numResults = 5,
    cssClasses = [],
    filters = {},
    term = ''
}) => {
    const {
        constructorApiKey,
        loading: configLoading
    } = useConstructorConfig();

    const [t] = useTranslation();
    const { authState } = useContext(AuthStore);
    const [recommendations, setRecommendations] = useState([]);
    const [recommendationsResultId, setRecommendationsResultId] = useState(0);
    const [recommendationsTitle, setRecommendationsTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const LoadingIndicator = useContext(LoaderStore);
    const isProductsInTab = () => {
        return cssClasses.includes('products-in-tab');
    };
    const settings = {
        arrows: true,
        dots: true,
        speed: 400,
        slidesToScroll: 3,
        slidesToShow: 3,
        initialSlide: 0,
        focusOnSelect: false,
        draggable: false,
        infinite: false,
        adaptiveHeight: false,
        prevArrow: <PrevIcon label={t(`Previous`)} />,
        nextArrow: <NextIcon label={t(`Next`)} />,
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    fade: false,
                    draggable: true,
                    slidesToScroll: 1,
                    slidesToShow: 1.3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    fade: false,
                    draggable: true,
                    slidesToScroll: 2,
                    slidesToShow: 2
                }
            }
        ]
    };
    const productsInTabSettings = {
        arrows: true,
        dots: true,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        focusOnSelect: false,
        draggable: false,
        infinite: false,
        adaptiveHeight: false,
        prevArrow: <PrevIcon label={t(`Previous`)} />,
        nextArrow: <NextIcon label={t(`Next`)} />,
        responsive: [
            {
                breakpoint: 768,
                settings: "unslick"
            }
        ]
    };

    useEffect(() => {
        if (!constructorApiKey) {
            return;
        }

        const constructorClientParams = {
            apiKey: constructorApiKey
        };

        if (isSignedIn()) {
            let userId = authState.user.id;
            if (userId) {
                let shaObj = new jsSHA('SHA-256', 'TEXT');
                shaObj.update(userId + 'ELEMIS');
                userId = shaObj.getHash('HEX');
                constructorClientParams.userId = userId;
            }
        }

        const constructorio = new ConstructorIOClient(constructorClientParams);

        const fetchRecommendations = async () => {
            try {
                const options = { numResults };

                cssClasses &&
                    cssClasses.forEach(className => {
                        if (className.startsWith('filter_')) {
                            const withoutPrefix = className.substring(7);
                            const lastUnderscoreIndex = withoutPrefix.lastIndexOf(
                                '_'
                            );
                            if (lastUnderscoreIndex !== -1) {
                                const filterName = withoutPrefix.substring(
                                    0,
                                    lastUnderscoreIndex
                                );
                                filters[filterName] = withoutPrefix.substring(
                                    lastUnderscoreIndex + 1
                                );
                            }
                        }

                        if (className.startsWith('term_')) {
                            const parts = className.split('_');
                            if (parts.length >= 2) {
                                term = parts.slice(1).join(' ');
                            }
                        }

                        if (
                            className &&
                            className.toLowerCase().startsWith('productid_')
                        ) {
                            itemsId = className.replace(/^productid_/i, '');
                        }

                        if (className.startsWith('pr_number_')) {
                            const partsLimit = className.split('pr_number_');
                            if (partsLimit.length >= 2) {
                                options.numResults = partsLimit[1];
                            }
                        }
                    });

                if (term) {
                    options.term = term;
                }

                if (itemsId) {
                    options.itemIds = Array.isArray(itemsId)
                        ? itemsId
                        : [itemsId];
                }

                if (Object.keys(filters).length > 0) {
                    options.filters = filters;
                }

                const results = await constructorio.recommendations.getRecommendations(
                    pod_id,
                    options
                );

                if (results?.response?.results) {
                    setRecommendations(results.response.results);
                    setRecommendationsResultId(results.result_id);
                    setRecommendationsTitle(results.response.pod.display_name);
                } else {
                    console.error('No recommendations found');
                }
            } catch (error) {
                console.error('Error fetching recommendations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommendations();
    }, [pod_id, itemsId, numResults, constructorApiKey]);

    if (loading || configLoading) {
        return <LoadingIndicator />;
    }
    const hasPromo = () => 1 > 1;

    const getPreviewSize = index => {
        let preIndex = index + 1;
        let postIndex = hasPromo()
            ? preIndex + Math.floor(preIndex / 5)
            : preIndex;

        return postIndex % 4 === 0 && postIndex % 12 !== 8 ? 300 : 300;
    };

    return (
        <div
            className={'co-recommendations'}
            data-cnstrc-recommendations=""
            data-cnstrc-recommendations-pod-id={pod_id}
            data-cnstrc-num-results={numResults}
            data-cnstrc-result-id={recommendationsResultId}
        >
            <h2 className={'product-slider-title'}>{recommendationsTitle}</h2>
            <Slider {...(isProductsInTab() ? productsInTabSettings : settings)}>
                {recommendations.map((item, index) => {
                    return (
                        <div className={'productItem'} key={item.data.id}>
                            <ConstructorRecommendationItem
                                product={item}
                                imageParam={{
                                    width: getPreviewSize(index)
                                }}
                                category={''}
                            />
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};
