import { mutationCreateNewsSubscriber } from "@corratech/checkout/graphql/mutationCreateNewsSubscriber";
import React, { useEffect, useState, useContext, useRef } from 'react';
import PropTypes from 'prop-types';
import { default as braintreeDropin } from 'braintree-web-drop-in';
import { useLazyQuery, useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Accordion, Alert, Button, Card } from 'react-bootstrap';
import { AuthStore, CartStore, LoaderStore } from '@corratech/context-provider';
import createTokenQuery from '@corratech/checkout/PaymentMethods/BraintreePayment/createBraintreeClientToken.graphql';
import setBraintreePaymentMethod from '@corratech/checkout/PaymentMethods/BraintreePayment/setBraintreePaymentMethod.graphql';
import setBraintreeApplepayMethod from 'src/_STORES_/us/node_modules/@corratech/checkout/PaymentMethods/BraintreePayment/setBraintreeApplepayMethod.graphql';
import { Checkbox } from '@corratech/form-components';
import { AuthBillingAddressForm } from './AuthBillingAddressForm';
import { UnAuthBillingAddressForm } from './UnAuthBillingAddressForm';
import { CheckoutAgreements } from '@corratech/checkout/CheckoutAgreements/CheckoutAgreements';
import PaymentLoaderWrapper from '@corratech/checkout/PaymentLoaderWrapper/PaymentLoaderWrapper';
import placeOrderMutation from '@corratech/checkout/graphql/placeOrder.graphql';
import setBillingAddressOnCart from '@corratech/checkout/graphql/setBillingAddressOnCart.graphql';
import NewsletterCheckbox from '@corratech/checkout/NewsletterCheckbox/NewsletterCheckbox';
import { isAddressValid } from '@corratech/checkout/util/isAddressValid';
import { RenderAppliedCredits } from '@corratech/checkout/RenderAppliedCredits';
import {
    useSetNotification,
    useGlobalOptions
} from '@corratech/context-provider';
import { PrivacyPolicy, PaymentFailure } from 'ModulesPath/Checkout';
import OGSubscriptionCheckBox from 'ModulesPath/OGSubscriptionCheckBox';
import SMSMarketingCheckbox from 'ModulesPath/SMSMarketingCheckbox';
import { deepValue } from '@corratech/tag-manager';
import { isOrderGroovePresent, orderGrooveOptins, extractOgOptions } from 'UtilPath/orderGroove';
import sendAttentiveSms from 'ModulesPath/SMSMarketingCheckbox/Data/Queries/sendAttentiveSMSData.graphql';
import { useStoreConfig } from 'ModulesPath/Checkout/useStoreConfig';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { Error as ErrorIcon } from 'ModulesPath/Icons';
import getCartQuery from '@corratech/checkout/graphql/cartQuery.graphql';
import { getCurrencySymbol } from "@corratech/checkout/util/getCurrencySymbol";
import getGrandTotal from "@corratech/checkout/util/getGrandTotal";
import { CardIcon, ApplePayIcon } from '@corratech/cart/src/PaymentIcons';

const BraintreePayment = props => {
    const {
        dataLayerAction,
        isPaymentFailureError,
        setPaymentFailureError,
        invalidShippingAddress,
        setShowPaymentForm,
        setIsSubscriptionPaymentError,
        setInvalidShippingAddress,
        activeKey,
        clearPaymentMethod,
        isApplePayOnly,
    } = props;
    // Check for braintree errors
    const [isShowingEmptyError, setShowingEmptyError] = useState(false);
    const [isEmailError, setEmailError] = useState(false);

    // Store reference to the dropinInstance - don't want this to disappear on every state change
    const dropinInstanceRef = useRef(null);

    const [t] = useTranslation();
    const options = useGlobalOptions();
    let displayName = 'Apple Pay Merchant';
    let applepayMethod = '';
    let setBraintreeApplepayPayment = '';

    const [newsletterChecked, setNewsletterChecked] = useState();

    const [threeDSecureErrorMsg, setThreeDSecureErrorMsg] = useState(null);
    const isThreeDSecureEnabled =
        options.storeConfig && options.storeConfig.verify_bt_3dsecure_enabled
            ? options.storeConfig.verify_bt_3dsecure_enabled
            : true;

    const { isVerify3DSecureEnable, isAlwaysRequest3DS } = useStoreConfig();

    const isChallengeRequested =
        isVerify3DSecureEnable && isAlwaysRequest3DS
            ? isAlwaysRequest3DS
            : false;

    const { setNotification } = useSetNotification();

    const [buttonState, setButtonState] = useState({
        text: t('Pay'),
        disabled: false
    });

    const isPaymentMethodAvailable = methodCode => {
        return (
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1
        );
    };

    const { cartState, dispatch } = useContext(CartStore);
    const { authState } = useContext(AuthStore);

    const [editingBilling, setEditingBilling] = useState(
        !!cartState.cart.is_virtual
    );

    //SMS subscription
    const { attentive_sms_key, attentive_sms_endpoint } = options.storeConfig;
    const smsMarkettingSubscribeCheckedRef = useRef(false);

    // OgSubscription
    const [ogSubscribeChecked, setOGSubscribeChecked] = useState(false);
    const isOGProductPresent = isOrderGroovePresent(cartState);
    const OGProducts = orderGrooveOptins();
    const OGProductIds = OGProducts?.map(ogProduct => ogProduct.product);

    //ReCaptcha
    const { actionV3PlaceOrder, actionV3SMSSignup } = useReCaptchaStoreConfig();
    const [captchaInvalidMsg, setCaptchaInvalidMsg] = useState(null);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);
    const [reCaptchaSMSToken, setReCaptchaSMSToken] = useState(null);
    const isV3SMSSignupEnable = !!(
        actionV3SMSSignup && actionV3SMSSignup === 'recaptcha_v3'
    );

    let recaptchaV3Publickey = '';
    let isV3PlaceOrderCaptchaEnable = false;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }
    if (actionV3PlaceOrder && actionV3PlaceOrder === 'recaptcha_v3') {
        isV3PlaceOrderCaptchaEnable = true;
    }

    const verifyReCaptchaTokenCallback = token => {
        setReCaptchaToken(token);
        setInterval(function() {
            resetReCaptchaToken();
        }, 100 * 1000);
    };

    const verifyReCaptchaTokenSMSSignupCallback = token => {
        setReCaptchaSMSToken(token);
        setInterval(function() {
            resetReCaptchaTokenSMSSignup();
        }, 110 * 1000);
    };

    const resetReCaptchaTokenSMSSignup = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, {
                    action: 'sms_signup'
                })
                .then(function(token) {
                    setReCaptchaSMSToken(token);
                });
        }
    };

    // Determines whether or not we should save card for Auth Users
    const [shouldSaveCard, setShouldSaveCard] = useState(
        !!authState.token && authState.token !== ''
    );

    // Mutation to set braintree payment info on the cart
    const [setBraintreePayment] = useMutation(setBraintreePaymentMethod, {
        variables: {
            cartId: cartState.cartId
        }
    });

    const [
        setSendAttentiveSMSData,
        { loading: attentiveLoading }
    ] = useMutation(sendAttentiveSms, {
        onCompleted: res => {
            if (
                !!dataLayerAction &&
                res.sendAttentiveSms &&
                res.sendAttentiveSms.status
            ) {
                dataLayerAction({
                    type: 'SMS_SIGNUP',
                    data: {}
                });
            }
        },
        onError: error => {
            let reCaptchaSMSFailure = false;

            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-recaptcha'
                    ) {
                        reCaptchaSMSFailure = true;
                    }
                }
            }

            if (!reCaptchaSMSFailure) {
                resetReCaptchaTokenSMSSignup();
            }
        }
    });

    if (isPaymentMethodAvailable('braintree_applepay') && !isOGProductPresent) {
        applepayMethod = 'braintree_applepay';
        [setBraintreeApplepayPayment] = useMutation(
            setBraintreeApplepayMethod,
            {
                variables: {
                    cartId: cartState.cartId
                }
            }
        );
    }

    const [cartQuery] = useLazyQuery(getCartQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    const setShouldShowRegisterForm = shouldShowRegisterForm => {
        setShowPaymentForm(false);
        setIsSubscriptionPaymentError(true);
        dispatch({ type: 'SHOW_REGISTER_FORM', shouldShowRegisterForm });
    };

    const [createNewsSubscriber] = useMutation(mutationCreateNewsSubscriber, {
        onError: res => {
            console.warn(res);
        }
    });

    // Mutation to placeorder
    const [placeOrder] = useMutation(placeOrderMutation, {
        variables: {
            cartId: cartState.cartId,
            OGProductIds: OGProductIds.toString(),
            ogOptions: extractOgOptions(OGProducts),
            reCaptchaToken: reCaptchaToken
        },
        onCompleted: res => {
            if (!!dataLayerAction) {
                dataLayerAction({
                    type: 'PLACE_ORDER',
                    data: {
                        ...cartState,
                        actionField: res
                    }
                });
            }

            if (newsletterChecked) {
                createNewsSubscriber({
                     variables: { subscriberEmail: cartState.cart.email, type: 'checkout' }
                 });
            }

            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
        },
        onError: error => {
            console.error(error);
            setPaymentFailureError(true);
            let reCaptchaFailure = false;
            let captchaInvalidMsg = null;
            cartQuery();

            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-recaptcha'
                    ) {
                        captchaInvalidMsg = error.graphQLErrors[idx]?.message;
                        reCaptchaFailure = true;
                    }

                    let message = error.graphQLErrors[idx]?.message;
                    if (message.includes('three_d_secure')) {
                        resetPaymentProcess();
                    }
                }
            }

            if (!reCaptchaFailure) {
                resetReCaptchaToken();
            }

            if (captchaInvalidMsg) {
                if (
                    captchaInvalidMsg ===
                    'You are required to login/register to subscribe to any products.'
                ) {
                    setShouldShowRegisterForm(true);
                }
                setCaptchaInvalidMsg(captchaInvalidMsg);
            }
            setButtonState({
                text: t('Place My Order'),
                disabled: false
            });
        }
    });

    const resetReCaptchaToken = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'place_order' })
                .then(function(token) {
                    setReCaptchaToken(token);
                });
        }
    };

    // Will use this to set Billing address if it is same as shipping address
    const [setBillingAddress, { loading: billingAddressLoading }] = useMutation(
        setBillingAddressOnCart,
        {
            variables: {
                cartId: cartState.cartId
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: res.setBillingAddressOnCart.cart
                });
            }
        }
    );

    // Create the Dropin instance
    const [getBraintreeToken, { data: clientTokenData }] = useMutation(
        createTokenQuery,
        {
            onCompleted: res => {
                if (!window.clientTokenData) {
                    window.clientTokenData = {};
                }
                window.clientTokenData.createBraintreeClientToken =
                    res?.createBraintreeClientToken;
            }
        }
    );

    const [isTokenRequested, setIsTokenRequested] = useState(false);

    useEffect(() => {
        (async () => {
            if (
                !window?.clientTokenData?.createBraintreeClientToken &&
                !isTokenRequested
            ) {
                setIsTokenRequested(true);
                await getBraintreeToken();
            } else if (window?.clientTokenData?.createBraintreeClientToken) {
                createNewDropinInstance(
                    window?.clientTokenData.createBraintreeClientToken
                );
            }
        })();
    }, [window?.clientTokenData, isTokenRequested]);

    //Only ASCII-printable characters is accepted
    const removeNonASCII = str => {
        if (str === null) str = '';
        else str = str.toString();
        return str.replace(/[^\x20-\x7E]/g, '');
    };

    const applepay = {
        displayName: displayName,
        paymentRequest: {
            total: {
                label: displayName,
                type: 'final',
                amount: cartState.cart.prices.grand_total.value
            }
        }
    };

    const billingAddress = deepValue(
        cartState,
        ['cart', 'billing_address'],
        {}
    );
    const threeDSecureParameters = {
        amount: cartState.cart.prices.grand_total.value,
        email: cartState.cart.email,
        challengeRequested: isChallengeRequested,
        billingAddress: {
            givenName: removeNonASCII(
                deepValue(billingAddress, ['firstname'], '')
            ),
            surname: removeNonASCII(
                deepValue(billingAddress, ['lastname'], '')
            ),
            phoneNumber: deepValue(billingAddress, ['telephone'], ''),
            streetAddress:
                deepValue(billingAddress, ['street', '0'], '') +
                ' ' +
                deepValue(billingAddress, ['street', '1'], ''),
            locality: deepValue(billingAddress, ['city'], ''),
            //region: deepValue(billingAddress, ['region', 'code'], ''),
            postalCode: deepValue(billingAddress, ['postcode'], ''),
            countryCodeAlpha2: deepValue(
                billingAddress,
                ['country', 'code'],
                'UK'
            )
        }
    };

    const createNewDropinInstance = async clientToken => {
        try {
            const isApplePay = isPaymentMethodAvailable(applepayMethod) && !isOGProductPresent && isApplePayOnly;
            const containerId = isApplePay ? 'braintree-dropin-container-applepay' : 'braintree-dropin-container';
            const dropinContainer = document.getElementById(containerId);

            if (!dropinContainer) {
                console.error('Drop-in container not found:', containerId);
                return;
            }

            dropinContainer.innerHTML = '';

            const createBraintreeParams = {
                authorization: clientToken,
                container: `#${containerId}`,
                threeDSecure: isThreeDSecureEnabled,
                ...(isApplePay ? { applePay: applepay, card: false } : {
                    card: {
                        overrides: {
                            fields: {
                                number: {
                                    maskInput: { showLastFour: true }
                                }
                            }
                        }
                    }
                })
            };

            const newInstance = await braintreeDropin.create(createBraintreeParams);

            if (isApplePay) {
                newInstance.on('paymentMethodRequestable', (event) => {
                    if (event.paymentMethodIsSelected && event.type === 'ApplePayCard') {
                        handleSubmitPayment();
                    }
                });
            }

            dropinInstanceRef.current = newInstance;

        } catch (error) {
            console.error('Error creating new drop-in instance:', error);
        }
    };

    const resetPaymentProcess = async () => {
        if (dropinInstanceRef.current) {
            try {
                await dropinInstanceRef.current.teardown();
                dropinInstanceRef.current = null;
            } catch (error) {
                console.error("Error tearing down the drop-in instance:", error);
            }
        }

        try {
            if (!window?.clientTokenData?.createBraintreeClientToken) {
                await getBraintreeToken(); // Эта функция должна обновить `clientTokenData`.
            } else {
                await createNewDropinInstance(
                    window.clientTokenData.createBraintreeClientToken
                );
            }
        } catch (error) {
            console.error('Error creating new drop-in instance: ', error);
        }
    };

    const checkThreeDSecureError = error => {
        if (
            error &&
            error.code &&
            error.code == 'THREEDS_LOOKUP_VALIDATION_ERROR'
        ) {
            if (
                error.details &&
                error.details.originalError &&
                error.details.originalError.details &&
                error.details.originalError.details.originalError &&
                error.details.originalError.details.originalError.error &&
                error.details.originalError.details.originalError.error.message
            ) {
                let errorMessage =
                    error.details.originalError.details.originalError.error
                        .message;
                if (
                    errorMessage === 'Billing line1 format is invalid.' &&
                    threeDSecureParameters.billingAddress.streetAddress.length >
                        50
                ) {
                    errorMessage = t(
                        'Billing line1 must be string and less than 50 characters. Please update the address and try again.'
                    );
                }
                setThreeDSecureErrorMsg(errorMessage);
                resetPaymentProcess();
            }
        }
    };

    const datalayerCheckoutOption = () => {
        dataLayerAction({
            type: 'CHECKOUT_OPTION',
            data: {
                step: 2,
                option: 'Credit Card'
            }
        });
    };

    const handlePlaceOrder = () => {
        // if the user wants to place order with the billing address the same as the shipping address
        // otherwise he/she submitted a billing address
        if (cartState.cart.isBillingSameAsShipping) {
            setBillingAddress({
                variables: {
                    cartId: cartState.cartId,
                    ...cartState.cart.shipping_addresses[0],
                    region: cartState.cart.shipping_addresses[0]?.region?.code,
                    countryCode:
                        cartState.cart.shipping_addresses[0]?.country?.code
                }
            })
                .then(res => {
                    if (res) {
                        datalayerCheckoutOption();
                        placeOrder();
                    }
                })
                .catch(e => {
                    console.log('error in setBillingAddress', e);
                    setButtonState({
                        text: t('Place My Order'),
                        disabled: false
                    });
                });
        } else {
            datalayerCheckoutOption();
            placeOrder();
        }
    };

    const smsSubscribe = async telephone => {
        setSendAttentiveSMSData({
            variables: {
                input: {
                    phone: telephone,
                    area: 'checkout'
                }
            },
            context: {
                headers: {
                    'X-ReCaptcha': reCaptchaSMSToken
                }
            }
        });
    };

    const handleSMSMarketingChange = (checked) => {
        smsMarkettingSubscribeCheckedRef.current = checked;
    };

    const paymentMethod = isApplePayOnly ? 'ApplePay' : 'CreditCard';

    const { isBillingSameAsShipping, billing_address, authNewBillingAddress, currentBillingAddressId, authenticated } = cartState.cart;

    const isBillingInvalid =
        !isBillingSameAsShipping && (
            !isAddressValid(billing_address) ||
            (authenticated && !authNewBillingAddress && !currentBillingAddressId && authState.user.addresses.length === 0) ||
            (!billing_address && !authenticated)
        ) || editingBilling;

    const isOGSubscriptionInvalid = isOGProductPresent && !ogSubscribeChecked;

    const handleSubmitPayment = async () => {
        setPaymentFailureError(false);
        setButtonState({
           text: t('Placing order...'),
           disabled: true
       });

        try {
            if (smsMarkettingSubscribeCheckedRef.current) {
                smsSubscribe(
                    cartState?.cart?.billing_address?.telephone ??
                    cartState?.cart?.shipping_addresses?.[0]?.telephone
                );
            }
        } catch (error) {
            console.error(`Attentive error: ${error}`);
        }

        try {
            const dropinInstance = dropinInstanceRef.current;

            if (dropinInstance && dropinInstance.isPaymentMethodRequestable()) {
                setShowingEmptyError(false);
                const paymentNonce = await (
                    isThreeDSecureEnabled && threeDSecureParameters
                        ? dropinInstance.requestPaymentMethod({ threeDSecure: threeDSecureParameters })
                        : dropinInstance.requestPaymentMethod()
                );

                if (paymentNonce.type === 'ApplePayCard') {
                    setBraintreeApplepayPayment({
                        variables: { braintreeNonce: paymentNonce.nonce }
                    }).then(res => {
                        if (res.data.setPaymentMethodOnCart.cart.selected_payment_method.code === applepayMethod) {
                            handlePlaceOrder();
                        }
                    });
                } else {
                    setBraintreePayment({
                        variables: {
                            braintreeNonce: paymentNonce.nonce,
                            storeInVault: shouldSaveCard
                        }
                    }).then(res => {
                        if (res.data.setPaymentMethodOnCart.cart.selected_payment_method.code === 'braintree') {
                            handlePlaceOrder();
                        }
                    });
                }
            } else {
                resetReCaptchaToken();
                setShowingEmptyError(true);
                setButtonState({
                   text: t('Place My Order'),
                   disabled: false
               });
            }
        } catch (e) {
            resetReCaptchaToken();
            checkThreeDSecureError(e);
            console.error(`Invalid Payment Details: ${e}`);
            setButtonState({
               text: t('Pay'),
               disabled: false
           });
        }
    };


    return (
        <Card
            as="li"
            bsPrefix={`payment-method-item ${activeKey === paymentMethod ? "payment-method-item-open" : ""}`}>
            <Card.Header>
                <Accordion.Toggle
                    as={Button}
                    variant="link"
                    bsPrefix="payment-method-toggle"
                    eventKey={paymentMethod}
                    aria-label={`Open ${paymentMethod} for Payment`}
                    onClick={clearPaymentMethod}
                >
                    <span className='svg-wrapper'>
                         {!isApplePayOnly ? <CardIcon/> : <ApplePayIcon/>}
                    </span>
                    {!isApplePayOnly ? t('Add New Credit or Debit Card') : t('Apple Pay')}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={paymentMethod}>
                <Card.Body bsPrefix="payment-method-inner">
                    <>
                        <div>
                            {threeDSecureErrorMsg && (
                                <Alert variant="danger">
                                    <ErrorIcon size={9} color={'#B70020'}/>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: threeDSecureErrorMsg
                                        }}
                                    />
                                </Alert>
                            )}
                        </div>
                        <div id={isApplePayOnly ? "braintree-dropin-container-applepay" : "braintree-dropin-container"} data-cs-mask />
                        {isV3PlaceOrderCaptchaEnable && loadReCaptcha(recaptchaV3Publickey)}
                        {!isApplePayOnly && !!authState.token && authState.token !== '' ? (
                            <div className={'should-save-credit-card'}>
                                <Checkbox
                                    disabled={isOGProductPresent}
                                    field={'should-save-card'}
                                    fieldState={{ value: shouldSaveCard }}
                                    onChange={() => {
                                        if (!isOGProductPresent) {
                                            setShouldSaveCard(!shouldSaveCard);
                                        }
                                    }}
                                    label={t('Save for my next payment')}
                                />
                            </div>
                        ) : null}
                        {authState.token && authState.token !== '' ? (
                            <AuthBillingAddressForm
                                invalidShippingAddress={invalidShippingAddress}
                                setInvalidShippingAddress={setInvalidShippingAddress}
                                editingBilling={editingBilling}
                                setEditingBilling={setEditingBilling}
                            />
                        ) : (
                            <UnAuthBillingAddressForm
                                invalidShippingAddress={invalidShippingAddress}
                                setInvalidShippingAddress={setInvalidShippingAddress}
                                editingBilling={editingBilling}
                                setEditingBilling={setEditingBilling}
                            />
                        )}

                        <RenderAppliedCredits/>
                        {isPaymentFailureError && <PaymentFailure/>}
                        <>
                            {isV3PlaceOrderCaptchaEnable && captchaInvalidMsg && (
                                <Alert variant="danger">
                                    <ErrorIcon size={9} color={'#B70020'}/>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: captchaInvalidMsg
                                        }}
                                    />
                                </Alert>
                            )}
                        </>
                        {isShowingEmptyError && (
                            <div style={{ color: '#d10000', marginBottom: '10px' }}>
                                {t('Please enter a valid credit card in the form above.')}
                            </div>
                        )}
                        <NewsletterCheckbox newsletterChecked={newsletterChecked} setNewsletterChecked={setNewsletterChecked} />
                        <SMSMarketingCheckbox setSMSMarkettingSubscribeChecked={handleSMSMarketingChange} />
                        {isV3SMSSignupEnable && (
                            <ReCaptcha
                                action="sms_signup"
                                sitekey={recaptchaV3Publickey}
                                verifyCallback={verifyReCaptchaTokenSMSSignupCallback}
                            />
                        )}
                        <PrivacyPolicy privacyPloicyLink={'/privacy-policy'}/>
                        {isOGProductPresent && (
                            <OGSubscriptionCheckBox
                                ogSubscribeChecked={ogSubscribeChecked}
                                setOGSubscribeChecked={setOGSubscribeChecked}
                                setShouldSaveCard={setShouldSaveCard}
                                shouldSaveCard={shouldSaveCard}
                            />
                        )}
                        <>
                            {isV3PlaceOrderCaptchaEnable && (
                                <ReCaptcha
                                    action="place_order"
                                    sitekey={recaptchaV3Publickey}
                                    verifyCallback={verifyReCaptchaTokenCallback}
                                />
                            )}
                        </>
                        {!isApplePayOnly && (
                            <Button
                                variant="secondary"
                                className={'submit-braintree-method'}
                                disabled={buttonState.disabled || isBillingInvalid || isOGSubscriptionInvalid || editingBilling}
                                onClick={handleSubmitPayment}
                            >
                                {buttonState.text}

                                <span className="btn-grand-total">
                                     {getCurrencySymbol(
                                         cartState.cart.prices.grand_total.currency
                                     ) + getGrandTotal(cartState.cart.prices, cartState.cart.applied_store_credit)}
                                </span>
                            </Button>
                        )}
                        <CheckoutAgreements prompt={false}/>

                        {buttonState.disabled && <PaymentLoaderWrapper/>}
                    </>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );
};

BraintreePayment.propTypes = {
    storeInVault: PropTypes.bool
};

export default BraintreePayment;
