import { formatPrice } from '@corratech/tag-manager';

function RemoveCartEvents(tagData) {
    const {
        id,
        name,
        price,
        quantityChange,
        category,
        variant,
        brand,
        urlKey,
        thumbnailUrl,
        productsDetails = [],
        currencyCode,
        cart,
        total,
        taxAmount,
        product
    } = tagData;

    const products = productsDetails.reverse();
    if (products.length > 0) {
        products.forEach((product) => {
            if (cart && Array.isArray(cart.items)) {
                cart.items.forEach((item) => {
                    if (item.itemsku === product.id) {
                        let saleDiscount = 0;
                        let itemDiscount = 0;
                        let regularPrice = item?.product?.price?.regularPrice?.amount?.value;
                        if (item?.product?.__typename === 'ConfigurableProduct') {
                            item?.product?.variants?.forEach((variant) => {
                                if (variant?.product?.sku === item.itemsku) {
                                    regularPrice = variant?.product?.price?.regularPrice?.amount?.value;
                                }
                            });
                        }
                        if (regularPrice && product.price && (regularPrice > product.price)) {
                            saleDiscount += (regularPrice - product.price) * item.quantity;
                        }
                        if (item.prices?.discounts) {
                            item.prices.discounts.forEach((discount) => {
                                itemDiscount += discount.amount.value;
                            });
                        }
                        product.discountValue = itemDiscount + saleDiscount;
                        product.originalPrice = regularPrice || product.price;
                        product.finalPrice = product.originalPrice - (itemDiscount / product.quantity);
                        product.variantId = item.itemsku;
                        product.productId = item.product?.sku ?? item.itemsku;
                    }
                });
            }
        });
    }

    const selectedProduct = products.find(product => product.id === id);

    return {
        event: 'removeFromCart',
        ecommerce: {
            currencyCode,
            remove: {
                products: [
                    {
                        id,
                        name,
                        price: formatPrice(price),
                        quantity: quantityChange,
                        category,
                        variant,
                        brand,
                        urlKey,
                        thumbnailUrl,
                        discountValue: selectedProduct?.discountValue || 0,
                        originalPrice: selectedProduct?.originalPrice || price,
                        finalPrice: selectedProduct?.finalPrice || price,
                        variantId: selectedProduct?.variantId || id || '',
                        productId: selectedProduct?.productId || product?.sku || ''
                    }
                ]
            }
        },
        cartContents: {
            currencyCode,
            products: products,
            totalPrice: total,
            totalPriceWithoutTax: (total - taxAmount).toFixed(2)
        },
        _clear: true
    };
}
export { RemoveCartEvents };
