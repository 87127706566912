import React, {useContext, useState, useEffect, Fragment, useRef, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { string, object, func, bool } from 'prop-types';
import {
    AuthStore,
    LoaderStore,
    signIn,
    useGlobalOptions
} from '@corratech/context-provider';
import { Form } from 'informed';
import {
    combine,
    isRequired,
    Message,
    validateEmail,
    Field,
    TextInput
} from '@corratech/form-components';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from 'react-apollo';
import isEmailAvailable from './Queries/isEmailAvailable.graphql';
import { Alert, Button } from 'react-bootstrap';
import './LoginForm.less';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { Check as CheckIcon } from 'react-feather';
import { handleEmailChange } from 'UtilPath/handleEmailChange';
import { useDebouncedCallback } from 'UtilPath/useDebouncedCallback';
import { useDataLayerAction } from '@corratech/tag-manager';

export const LoginForm = props => {
    const [t] = useTranslation();
    const formApiRef = useRef(null);
    const setFormApi = useCallback(api => (formApiRef.current = api), []);

    const {
        updateEmail,
        enteredEmail,
        forgotPasswordClick,
        showLogin,
        setEmailAvailable,
        className,
        css
    } = props;

    const { authState, dispatch } = useContext(AuthStore);

    const LoadingIndicator = useContext(LoaderStore);

    const [loading, setLoading] = useState(false);
    const dataLayerAction = useDataLayerAction();

    const [
        checkIsEmailAvailable,
        { loading: loadingCheckIsEmailAvailable, error }
    ] = useLazyQuery(isEmailAvailable, {
        fetchPolicy: 'no-cache',
        onCompleted: ({ isEmailAvailable }) => {
            setEmailAvailable({
                status: isEmailAvailable.is_email_available
            });
            if (isV3PlaceEmailCheckCaptchaEnable) {
                resetReCaptchaTokenEmailCheck();
            }
        },
        onError: error => {
            let reCaptchaFailure = false;
            let captchaInvalidMsg = null;
            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-recaptcha'
                    ) {
                        captchaInvalidMsg = error.graphQLErrors[idx]?.message;
                        reCaptchaFailure = true;
                    }
                }
            }

            if (!reCaptchaFailure && isV3PlaceEmailCheckCaptchaEnable) {
                resetReCaptchaTokenEmailCheck();
            }

            if (captchaInvalidMsg) {
                authState.error = captchaInvalidMsg;
            }
        }
    });

    //ReCaptcha
    const options = useGlobalOptions();
    const {
        actionV3PlaceEmailCheck,
        actionV3PlaceSingIn
    } = useReCaptchaStoreConfig();
    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);
    const [reCaptchaTokenEmailCheck, setReCaptchaTokenEmailCheck] = useState(
        null
    );
    const [reCaptchaTokenSingIn, setReCaptchaTokenSingIn] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3PlaceEmailCheckCaptchaEnable = null;
    let isV3PlaceSingInCaptchaEnable = null;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceEmailCheck && actionV3PlaceEmailCheck === 'recaptcha_v3') {
        isV3PlaceEmailCheckCaptchaEnable = true;
    } else {
        isV3PlaceEmailCheckCaptchaEnable = false;
    }
    if (actionV3PlaceSingIn && actionV3PlaceSingIn === 'recaptcha_v3') {
        isV3PlaceSingInCaptchaEnable = true;
    } else {
        isV3PlaceSingInCaptchaEnable = false;
    }

    useEffect(() => {
        const scriptLoaded = loadReCaptcha(recaptchaV3Publickey, () => {
            setIsReCaptchaLoaded(true);
        });
        if (!scriptLoaded) {
            setIsReCaptchaLoaded(true);
        }
    }, [recaptchaV3Publickey]);

    const verifyReCaptchaTokenEmailCheckCallback = token => {
        setReCaptchaTokenEmailCheck(token);
        setInterval(function() {
            resetReCaptchaTokenEmailCheck();
        }, 100 * 1000);
    };

    const verifyReCaptchaTokenSingInCallback = token => {
        setReCaptchaTokenSingIn(token);
        setInterval(function() {
            resetReCaptchaTokenSingIn();
        }, 110 * 1000);
    };

    const resetReCaptchaTokenEmailCheck = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'email_check' })
                .then(function(token) {
                    setReCaptchaTokenEmailCheck(token);
                });
        }
    };

    const resetReCaptchaTokenSingIn = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'sing_in' })
                .then(function(token) {
                    setReCaptchaTokenSingIn(token);
                });
        }
    };

    useEffect(() => {
        if (authState.error !== null) {
            setLoading(false);
        }
    }, [authState.error]);

    /**
     * clear error message on login change
     */
    useEffect(() => {
        if (!showLogin) {
            hideErrorMessage();
        }
    }, [showLogin]);

    const onEmailChange = useDebouncedCallback(
        (value) => handleEmailChange(value, formApiRef, updateEmail, hideErrorMessage),
        300
    );

    const hideErrorMessage = () => {
        dispatch({
            type: 'SET_AUTH_ERROR',
            error: null
        });
    };

    const [attemptedSubmit, setAttemptedSubmit] = useState(false);
    const [
        attemptedSubmittWithLoyalty,
        setAttemptedSubmittWithLoyalty
    ] = useState(false);

    const onSubmitLoaylty = async formState => {
        if (showLogin) {
            signInSubmitWithLoyalty(formState);
        } else {
            checkEmail(formState);
        }
    };

    const onSubmitHanlde = async formState => {
        if (showLogin) {
            signInSubmit(formState);
        } else {
            checkEmail(formState);
        }
    };

    const signInSubmitWithLoyalty = async formState => {
        try {
            if (isV3PlaceSingInCaptchaEnable && !reCaptchaTokenSingIn) {
                console.log('reCAPTCHA is not initialized yet');
                setAttemptedSubmittWithLoyalty(true);
                return;
            }

            setLoading(true);
            await signInWithLoyalty({
                credentials: {
                    username: formState.email,
                    password: formState.password,
                    reCaptchaToken: reCaptchaTokenSingIn,
                    listrakNewsletterOptIn: isAlredyListrakSubscribed
                        ? isAlredyListrakSubscribed
                        : isSubscription
                },
                dispatch: dispatch
            });
            if (isV3PlaceSingInCaptchaEnable) {
                resetReCaptchaTokenSingIn();
            }

        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    const signInSubmit = async formState => {
        try {
            if (isV3PlaceSingInCaptchaEnable && !reCaptchaTokenSingIn) {
                console.log('reCAPTCHA is not initialized yet');
                setAttemptedSubmit(true);
                return;
            }
            setLoading(true);
            const userData = await signIn({
                credentials: {
                    username: formState.email,
                    password: formState.password,
                    reCaptchaToken: reCaptchaTokenSingIn
                },
                dispatch: dispatch
            });
            dataLayerAction({
                type: 'CUSTOMER_LOGIN',
                data: userData
            });

            if (isV3PlaceSingInCaptchaEnable) {
                resetReCaptchaTokenSingIn();
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const checkEmail = async formState => {
        if (isV3PlaceEmailCheckCaptchaEnable && !reCaptchaTokenEmailCheck) {
            console.log('reCAPTCHA is not initialized yet');
            return;
        }
        const valueWithoutSpaces = (formState.email || '').trim() === '' ? '' : formState.email.replace(/\s+/g, '');
        updateEmail(valueWithoutSpaces);
        await checkIsEmailAvailable({
            variables: {
                email: formState.email
            },
            context: {
                headers: {
                    'X-ReCaptcha': reCaptchaTokenEmailCheck,
                    'X-ReCaptcha-Check': true
                }
            }
        });
        if (isV3PlaceEmailCheckCaptchaEnable) {
            resetReCaptchaTokenEmailCheck();
        }
    };

    useEffect(() => {
        if (
            (reCaptchaTokenEmailCheck || reCaptchaTokenSingIn) &&
            attemptedSubmit
        ) {
            onSubmitHanlde(formState);
            setAttemptedSubmit(false);
        }
        if (
            (reCaptchaTokenEmailCheck || reCaptchaTokenSingIn) &&
            attemptedSubmittWithLoyalty
        ) {
            signInSubmitWithLoyalty(formState);
            setAttemptedSubmit(false);
        }
    }, [
        reCaptchaTokenEmailCheck,
        reCaptchaTokenSingIn,
        attemptedSubmit,
        attemptedSubmittWithLoyalty
    ]);

    if (loadingCheckIsEmailAvailable || loading) {
        return <LoadingIndicator />;
    }

    if (!isReCaptchaLoaded) {
        return <LoadingIndicator />;
    }

    const renderForm = () => {
        return (
            <div
                aria-labelledby={'account-login-form-heading'}
                className={
                    'account-login-form account-form-wrapper ' +
                    (className || '')
                }
                css={css}
            >
                {showLogin && (
                    <div
                        id="account-login-form-heading"
                        className={'customer exist'}
                    >
                        {t('You already have an account with us.')}
                    </div>
                )}
                <Form
                    getApi={setFormApi}
                    onSubmit={isSubscription ? onSubmitLoaylty : onSubmitHanlde}
                >
                    <Field
                        label={t(`Email Address`)}
                        labelText={t(`email_address`)}
                        required={true}
                    >
                        <TextInput
                            type="email"
                            field="email"
                            autoComplete="email"
                            inputMode="email"
                            placeholder={t(`Please enter your email address`)}
                            id="email_address"
                            aria-required="true"
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: t(props.requiredText)
                                },
                                {
                                    fn: validateEmail,
                                    text: t(props.invalidEmailText)
                                }
                            ])}
                            initialValue={enteredEmail}
                            validateOnBlur
                            onValueChange={onEmailChange}
                            data-cs-mask
                        />
                    </Field>
                    {showLogin && (
                        <Fragment>
                            <Field
                                label={t(`Password`)}
                                labelText={t(`password`)}
                                required={true}
                            >
                                <TextInput
                                    field="password"
                                    type="password"
                                    autoComplete="new-password"
                                    placeholder={t(`Password`)}
                                    id="password"
                                    aria-required="true"
                                    validate={combine([
                                        {
                                            fn: isRequired,
                                            text: t(props.requiredText)
                                        }
                                    ])}
                                    validateOnChange
                                    autoFocus
                                    onValueChange={hideErrorMessage}
                                />
                            </Field>

                            <div className={'root-checkbox-wrapper'}>
                                {!isAlredyListrakSubscribed && (
                                    <label
                                        className={'root-checkbox'}
                                        htmlFor={'listrak_newsletter_opt_in'}
                                    >
                                        <span className={'icon-checkbox'}>
                                            <CheckIcon size={18} />
                                        </span>
                                        <input
                                            field="subscription"
                                            type={'checkbox'}
                                            className={'input-checkbox'}
                                            id={'listrak_newsletter_opt_in'}
                                            checked={isSubscription}
                                            onChange={e => {
                                                setIsSubscription(
                                                    e.target.checked
                                                );
                                            }}
                                        />
                                        <span className={'label-checkbox'}>
                                            <span>
                                                {t(
                                                    'I agree to receive email communications from ELEMIS.'
                                                )}
                                            </span>
                                        </span>
                                    </label>
                                )}
                            </div>
                        </Fragment>
                    )}
                    <div>
                        {null !== authState.error && (
                            <Alert variant="danger">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: authState.error
                                    }}
                                />
                            </Alert>
                        )}
                    </div>
                    <div className={'actions-toolbar'}>
                        <Button
                            type="submit"
                            size="lg"
                            variant={'primary'}
                            block
                        >
                            {showLogin ? t('Sign In') : t('Continue')}
                        </Button>

                        {showLogin && (
                            <Link
                                title={t(`Forgot password?`)}
                                to={'#'}
                                onClick={forgotPasswordClick}
                                className={'forgot-password-link'}
                            >
                                {t(`Forgot password?`)}
                            </Link>
                        )}
                    </div>
                </Form>
            </div>
        );
    };

    return (
        <div>
            {isV3PlaceEmailCheckCaptchaEnable && (
                <ReCaptcha
                    action="email_check"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenEmailCheckCallback}
                />
            )}
            {isV3PlaceSingInCaptchaEnable && (
                <ReCaptcha
                    action="sing_in"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenSingInCallback}
                />
            )}

            {(isV3PlaceEmailCheckCaptchaEnable &&
                reCaptchaTokenEmailCheck !== null) ||
            (isV3PlaceSingInCaptchaEnable && reCaptchaTokenSingIn !== null) ||
            (isV3PlaceEmailCheckCaptchaEnable === false &&
                isV3PlaceSingInCaptchaEnable === false) ? (
                renderForm()
            ) : (
                <LoadingIndicator />
            )}
        </div>
    );
};

LoginForm.propTypes = {
    className: string,
    css: object,
    enteredEmail: string,
    updateEmail: func,
    showLogin: bool,
    requiredText: string,
    invalidEmailText: string
};

LoginForm.defaultProps = {
    requiredText: 'This field is required',
    invalidEmailText: 'Please enter a valid email, such as example@example.com'
};
