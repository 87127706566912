import React from 'react';

export const PrivacyPolicy = props => {
    const { termsnConditionLink, privacyPolicyLink } = props;
    return (
        <div className={'co-privacy-policy'}>
            By placing your order, you agree to our Website{' '}
            <a
                target="_blank"
                href={termsnConditionLink}
                className={'terms-link'}
            >
                Terms & Conditions
            </a>
            . For more information on the processing of your data and your
            privacy rights view our{' '}
            <a
                target="_blank"
                href={privacyPolicyLink}
                className={'policy-link'}
            >
                Privacy Policy
            </a>
            .
        </div>
    );
};

PrivacyPolicy.defaultProps = {
    termsnConditionLink: '/terms-and-conditions',
    privacyPolicyLink: '/privacy-policy'
};
