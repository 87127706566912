import React from 'react';

export const PayPalIcon = () => {
    return (
        <svg
            role="img" aria-label="Payment Method PayPal Icon" xmlns="http://www.w3.org/2000/svg" width="15" height="18"
            viewBox="0 0 15 18" fill="none">

            <path
                d="M12.2192 1.99615C11.4438 1.11923 10.0592 0.75 8.27769 0.75H3.11772C2.94257 0.749862 2.77307 0.811982 2.6395 0.925271C2.50592 1.03856 2.41696 1.19564 2.38849 1.36846L0.256199 14.9931C0.245501 15.0564 0.248678 15.1212 0.265512 15.1831C0.282345 15.2451 0.312432 15.3026 0.353691 15.3518C0.394951 15.4009 0.446397 15.4405 0.504472 15.4679C0.562548 15.4952 0.625864 15.5096 0.690043 15.51H3.87464L4.67771 10.4331L4.65002 10.59C4.67848 10.4172 4.76744 10.2601 4.90102 10.1468C5.0346 10.0335 5.2041 9.9714 5.37925 9.97154H6.89308C9.86537 9.97154 12.1915 8.76231 12.8746 5.26385L12.9207 4.96846C13.0535 4.45392 13.0599 3.91489 12.9394 3.39733C12.8189 2.87978 12.5749 2.39906 12.2284 1.99615"
                fill="#003086"/>
            <path
                d="M12.2192 1.99615C11.4438 1.11923 10.0592 0.75 8.27769 0.75H3.11772C2.94257 0.749862 2.77307 0.811982 2.6395 0.925271C2.50592 1.03856 2.41696 1.19564 2.38849 1.36846L0.256199 14.9931C0.245501 15.0564 0.248678 15.1212 0.265512 15.1831C0.282345 15.2451 0.312432 15.3026 0.353691 15.3518C0.394951 15.4009 0.446397 15.4405 0.504472 15.4679C0.562548 15.4952 0.625864 15.5096 0.690043 15.51H3.87464L4.67771 10.4331L4.65002 10.59C4.67848 10.4172 4.76744 10.2601 4.90102 10.1468C5.0346 10.0335 5.2041 9.9714 5.37925 9.97154H6.89308C9.86537 9.97154 12.1915 8.76231 12.8746 5.26385L12.9207 4.96846C13.0535 4.45392 13.0599 3.91489 12.9394 3.39733C12.8189 2.87978 12.5749 2.39906 12.2284 1.99615"
                fill="#003086"/>
            <path
                d="M5.51771 4.98692C5.54006 4.832 5.61788 4.69046 5.73672 4.5886C5.85556 4.48674 6.00734 4.43147 6.16385 4.43308H10.2069C10.655 4.43136 11.1026 4.46531 11.5454 4.53462L11.8869 4.59923L12.2007 4.68231L12.3577 4.72846C12.5486 4.79306 12.7338 4.87333 12.9115 4.96846C13.0443 4.45392 13.0507 3.91489 12.9302 3.39733C12.8096 2.87978 12.5657 2.39906 12.2192 1.99615C11.4438 1.11923 10.0592 0.75 8.27769 0.75H3.11772C2.94257 0.749862 2.77307 0.811982 2.6395 0.925271C2.50592 1.03856 2.41696 1.19564 2.38849 1.36846L0.256199 14.9931C0.245501 15.0564 0.248678 15.1212 0.265512 15.1831C0.282345 15.2451 0.312432 15.3026 0.353691 15.3518C0.394951 15.4009 0.446397 15.4405 0.504472 15.4679C0.562548 15.4952 0.625864 15.5096 0.690043 15.51H3.87464L4.67771 10.4331L5.53617 4.98692H5.51771Z"
                fill="#002F85"/>
            <path
                d="M12.9105 4.96777L12.8643 5.26316C12.1813 8.76162 9.85512 9.97085 6.88283 9.97085H5.369C5.19385 9.97071 5.02435 10.0328 4.89077 10.1461C4.75719 10.2594 4.66823 10.4165 4.63977 10.5893L3.86439 15.5093L3.64285 16.8939C3.63184 16.9506 3.63364 17.0091 3.64813 17.065C3.66261 17.121 3.68942 17.173 3.72658 17.2172C3.76374 17.2614 3.81032 17.2968 3.86291 17.3207C3.9155 17.3447 3.97278 17.3565 4.03054 17.3555H6.70745C6.86396 17.3571 7.01574 17.3018 7.13458 17.1999C7.25342 17.0981 7.33125 16.9565 7.3536 16.8016L7.37206 16.6632L7.87975 13.4601L7.91667 13.2755C7.94277 13.1255 8.02097 12.9896 8.13749 12.8916C8.25401 12.7937 8.40137 12.74 8.55359 12.7401H8.95051C11.5536 12.7401 13.5936 11.6785 14.1843 8.62316C14.3413 8.10154 14.3747 7.55053 14.2818 7.01379C14.1888 6.47705 13.9722 5.96932 13.6489 5.53085C13.4391 5.29981 13.1888 5.10899 12.9105 4.96777Z"
                fill="#009BDD"/>
            <path
                d="M12.2006 4.6829L11.8867 4.59982C11.7735 4.57502 11.6596 4.55347 11.5452 4.53521C11.1022 4.4688 10.6546 4.43793 10.2067 4.4429H6.16369C6.00855 4.44097 5.85789 4.49494 5.73925 4.59494C5.62061 4.69493 5.54191 4.83428 5.51754 4.98751L4.66832 10.4337L4.64062 10.5906C4.66909 10.4178 4.75805 10.2607 4.89163 10.1474C5.02521 10.0341 5.1947 9.97199 5.36985 9.97213H6.88369C9.85598 9.97213 12.1821 8.7629 12.8652 5.26444L12.9113 4.96905C12.7336 4.874 12.5484 4.79373 12.3575 4.72905L12.2006 4.6829Z"
                fill="#012069"/>
        </svg>
    )
};