import React, { useContext, useEffect, useState } from 'react';
import {
    CartStore,
    LoaderStore,
    useSetNotification
} from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import setShippingMethodsOnCart from '../graphql/setShippingMethodsOnCart.graphql';
import { getCurrencySymbol } from '../util/getCurrencySymbol';
import './ShippingMethodForm.less';
import { CustomRadioButton } from '@corratech/form-components';
import { Alert } from 'react-bootstrap';
import { AlertTriangle as Warning } from 'react-feather';
import { getDeliveryDateRange } from '@corratech/checkout/util/getDeliveryDateRange';

export const ShippingMethodForm = ({
    shippingMsg,
    warningIcon,
    shippingMethodRef,
    invalidShippingMsg,
    setInvalidShippingMsg,
    isFromPaypal
}) => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const { clearNotification } = useSetNotification();
    const LoadingIndicator = useContext(LoaderStore);

    const [setShippingMethod, { data, loading }] = useMutation(
        setShippingMethodsOnCart
    );

    useEffect(() => {
        if (data) {
            const updatedCart = cartState.cart;
            updatedCart.shipping_addresses[0].selected_shipping_method =
                data.setShippingMethodsOnCart.cart.shipping_addresses[0].selected_shipping_method;
            updatedCart.prices = data.setShippingMethodsOnCart.cart.prices;
            updatedCart.items = data.setShippingMethodsOnCart.cart.items;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    }, [data]);

    useEffect(() => {
        if (
            shippingMethodRef &&
            shippingMethodRef.current &&
            invalidShippingMsg
        ) {
            shippingMethodRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [invalidShippingMsg, cartState]);

    useEffect(() => {
        const address = cartState.cart?.shipping_addresses?.[0];
        if (
            address &&
            !address.selected_shipping_method &&
            Array.isArray(address.available_shipping_methods)
        ) {
            const freeShippingMethod = address.available_shipping_methods.find(
                method => method.amount.value === 0
            );
            freeShippingMethod && selectMethod(freeShippingMethod.method_code, freeShippingMethod.carrier_code);
        }
    }, []);

    const selectMethod = (methodCode, carrierCode) => {
        setInvalidShippingMsg(null);
        clearNotification();
        setShippingMethod({
            variables: {
                cartId: cartState.cartId,
                carrierCode,
                methodCode
            }
        });
    };

    const triggerPaypal = () => {
        var btn = document.getElementById('paypal-payment-button');
        btn.click();
    };

    const isInternationalShipping =
        cartState.cart.shipping_addresses[0].country.code !== 'GB';

    return (
        <div className={'shipping-method-form'} ref={shippingMethodRef}>
            {shippingMsg && (
                <Alert variant={'warning'}>
                    {warningIcon ? (
                        warningIcon
                    ) : (
                        <Warning size={14} strokeWidth={'4'} color={'#000'} />
                    )}
                    {t(shippingMsg)}
                </Alert>
            )}
            {invalidShippingMsg && (
                <Alert variant={'warning'}>
                    {warningIcon ? (
                        warningIcon
                    ) : (
                        <Warning size={14} strokeWidth={'4'} color={'#000'} />
                    )}
                    {isFromPaypal &&
                    invalidShippingMsg === 'has-trigger-paypal' ? (
                        <>
                            <span>
                                There are no available shipping methods for this
                                address. Please change your address{' '}
                            </span>
                            <span
                                onClick={() => triggerPaypal()}
                                className={'paypal-trigger'}
                            >
                                here
                            </span>
                        </>
                    ) : (
                        t(invalidShippingMsg)
                    )}
                </Alert>
            )}
            <div className="loading-or-form">
                {loading && (
                    <div className="loading-indicator-container">
                        <LoadingIndicator />
                    </div>
                )}
                {!!cartState.cart.shipping_addresses[0]
                    .available_shipping_methods.length > 0
                    ? !cartState.cart.shipping_addresses[0]
                          .available_shipping_methods[0].available
                        ? cartState.cart.shipping_addresses[0]
                              .available_shipping_methods[0].error_message
                        : cartState.cart.shipping_addresses[0].available_shipping_methods.map(
                              method => (
                                  <div
                                      className={'shipping-method'}
                                      key={
                                          method.carrier_code +
                                          ';' +
                                          method.method_code
                                      }
                                      onClick={event => {
                                          event.stopPropagation();
                                          selectMethod(
                                              method.method_code,
                                              method.carrier_code
                                          );
                                      }}
                                  >
                                      <CustomRadioButton
                                          active={
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method &&
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method
                                                  .carrier_code ===
                                                  method.carrier_code &&
                                              cartState.cart
                                                  .shipping_addresses[0]
                                                  .selected_shipping_method
                                                  .method_code ===
                                                  method.method_code
                                          }
                                          label={`${getCurrencySymbol(
                                              method.price_excl_tax.currency
                                          )} ${method.price_excl_tax.value} ${
                                              method.method_title
                                          } ${method.carrier_title}`}
                                      >
                                          <label
                                              htmlFor={`${method.carrier_code}${method.method_code}`}
                                              style={{ cursor: 'pointer' }}
                                          >
                                              <div>
                                                  <div className={'shipping-method-title-price'}>
                                                  <span
                                                      className={
                                                          'shipping-method-title'
                                                      }
                                                  >
                                                  {method.method_title}
                                              </span>
                                                      <span
                                                          className={
                                                              'shipping-method-price'
                                                          }
                                                      >
                                                 {method.price_excl_tax.value === 0
                                                     ? "Free"
                                                     : `${getCurrencySymbol(method.price_excl_tax.currency)}${method.price_excl_tax.value}`}
                                              </span>
                                                  </div>
                                                  {method.narvar_data && (
                                                      <span
                                                          className={
                                                              'shipping-method-narvar'
                                                          }
                                                      >
                                                            {getDeliveryDateRange(method.narvar_data.promise)}
                                                        </span>
                                                  )}
                                                  {method.carrier_title && method.carrier_title.trim() && (
                                                      <span
                                                          className={
                                                              'shipping-method-carrier'
                                                          }
                                                      >
                                                        {method.carrier_title}
                                                      </span>
                                                  )}
                                                  {method.amstartes_comment && !method.narvar_data && (
                                                      <span
                                                          className={
                                                              'shipping-method-carrier'
                                                          }
                                                      >
                                                        {method.amstartes_comment}
                                                      </span>
                                                  )}
                                                  {isInternationalShipping && (
                                                      <span
                                                          className={
                                                              'shipping-method-description'
                                                          }
                                                      >
                                                      {t(
                                                          'Please note, orders that are shipping outside of the UK will not be charged VAT but may be subject to customs charges and tax that will need to be paid directly.'
                                                      )}
                                                  </span>
                                                  )}
                                              </div>
                                          </label>
                                      </CustomRadioButton>
                                  </div>
                              )
                          )
                    : !isFromPaypal && (
                          <div>
                              {t(
                                  'No available shipping methods for this address. Please try a different address.'
                              )}
                          </div>
                      )}
            </div>
        </div>
    );
};
