function CagegoryFilterEvent(tagData) {
    const output = [];
    for (const key in tagData) {
        if (tagData.hasOwnProperty(key)) {
            tagData[key].forEach(value => {
                output.push({
                    [key]: value,
                    operator: "eq"
                });
            });
        }
    }
    return {
        event: 'category_filter',
        eventMetaData: output
    };
}
export { CagegoryFilterEvent };
