import React, {useContext} from 'react';
import getGWPThresholdBanner from '../queries/getGwpThresholdBanner.graphql';
import { useQuery } from 'react-apollo';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { isOrderGroovePresent } from 'UtilPath/orderGroove';
import {useTranslation} from "react-i18next";

function formatMessage(message, replacements) {
    let formattedMessage = message.replace(/\\n/g, '\n');
    for (const [key, value] of Object.entries(replacements)) {
        formattedMessage = formattedMessage.replace(new RegExp(key, 'g'), value);
    }

    const parts = formattedMessage.split('\n');
    const numberOfParts = parts.length;

    return parts.map((part, index) => (
        <React.Fragment key={index}>
            {part}
            {index < numberOfParts - 1 && <br />}
        </React.Fragment>
    ));
}

function formatNumber(number) {
    const roundedNumber = Math.round(number * 100) / 100;

    if (roundedNumber === Math.floor(roundedNumber)) {
        return roundedNumber.toString();
    }

    return roundedNumber.toFixed(2);
}

export const GWPThresholdBanner = props => {
    const [t] = useTranslation();
    const LoadingIndicator = useContext(LoaderStore);
    const { cartState } = useContext(CartStore);
    const appliedCoupons = cartState.cart.applied_coupons;
    const isOGPresent = isOrderGroovePresent(cartState);
    const { loading, error, data } = useQuery(getGWPThresholdBanner);

    if (!!loading) return <LoadingIndicator />;
    if (!!error) return null;

    const {
        gwp_enabled: gwpEnabled,
        gwp_image: gwpImage,
        gwp_currency: gwpCurrency,
        gwp_amount: gwpAmount,
        gwp_threshold: gwpThreshold,
        gwp_info_message: gwpInfoMessage,
        gwp_success_message: gwpSuccessMessage,
        gwp_condition_message: gwpConditionMessage,
        gwp_apply_message: gwpApplyMessage,
        gwp_promo_code: gwpPromoCode,
        ignore_products_from_calculation: ignoreProductsFromCalculation,
        exclude_subscription_orders: excludeSubscriptionOrders
    } = data.gwpThresholdBanner;

    const gwpThresholdNumber = parseFloat(gwpThreshold);

    if (isNaN(gwpThresholdNumber)) return null;
    if (!gwpEnabled || (excludeSubscriptionOrders && isOGPresent)) return null;

    const getCurrentAmount = () => {
        const grandTotal = cartState?.cart?.prices?.grand_total?.value;

        if (!grandTotal) return 0;
        if (!ignoreProductsFromCalculation || ignoreProductsFromCalculation.trim() === "") {
            return grandTotal;
        }

        const skuArray = ignoreProductsFromCalculation.split(',').map(sku => sku.trim());
        const cartItems = cartState.cart.items;

        const totalPrice = cartItems.reduce((sum, item) => {
            const itemPrice = item.prices?.row_total_including_tax?.value;
            let itemSku = null;

            if (item.itemsku) {
                itemSku = item.itemsku.trim();
            } else if (item.product?.sku) {
                itemSku = item.product.sku.trim();
            }

            if (!itemSku || typeof itemPrice !== 'number') {
                return sum;
            }

            return skuArray.includes(itemSku) ? sum + itemPrice : sum;
        }, 0);

        return grandTotal - totalPrice;
    };
    const currentAmount = getCurrentAmount();
    const totalAmount = gwpThresholdNumber;
    const percentageAmount = Math.min((currentAmount / totalAmount) * 100, 100);
    const remainingAmount = formatNumber(totalAmount - currentAmount);
    const isThresholdReached = currentAmount >= totalAmount;
    const isCouponApplied = appliedCoupons && appliedCoupons[0]?.code === gwpPromoCode;

    const replacements = {
        "\\$\\{gwp_amount\\}": gwpCurrency + gwpAmount,
        "\\$\\{gwp_threshold\\}": gwpCurrency + gwpThreshold,
        "\\$\\{gwp_remaining_amount\\}": gwpCurrency + remainingAmount
    };

    return (
        <div className={`gwp-threshold-banner${isThresholdReached ? ' gwp-threshold-banner-reached' : ''}`}>
            <div className={'gwp-threshold-banner-image'}>
                <img src={gwpImage} alt={t('GWP Image')} />
            </div>
            <div className={'gwp-threshold-banner-text'}>
                <p className={'gwp-threshold-banner-text-main'}>
                    {isCouponApplied ? (
                        formatMessage(gwpApplyMessage, replacements)
                    ) : !isThresholdReached ? (
                        formatMessage(gwpInfoMessage, replacements)
                    ) : (
                        <>
                            {formatMessage(gwpSuccessMessage, replacements)}
                            &nbsp;
                            {<a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    navigator?.clipboard?.writeText(gwpPromoCode);
                                }}
                                className={'gwp-threshold-banner-text-link'}
                            >
                                {gwpPromoCode}
                            </a>}
                        </>
                    )}
                </p>
                {!isCouponApplied && isThresholdReached && (
                    <p className={'gwp-threshold-banner-text-additional'}>
                        {gwpConditionMessage}
                    </p>
                )}
                <div className={'gwp-threshold-banner-bar'}>
                    <div className={'gwp-threshold-banner-bar-fill'}
                         style={{ width: `${percentageAmount}%` }}></div>
                </div>
            </div>
        </div>
    );
};

GWPThresholdBanner.propTypes = {};

GWPThresholdBanner.defaultProps = {};