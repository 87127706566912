import React, {
    useContext,
    useState,
    useRef,
    useEffect
} from 'react';
import { useQuery } from 'react-apollo';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SelectInputFormik, TextInputFormik } from '@corratech/form-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Info } from 'react-feather';

import { AutoSubmit } from '@corratech/checkout/forms/AutoSubmit';
import { Field as CorraField } from '@corratech/checkout/forms/Field';
import QUERY_GET_COUNTRY from '@corratech/checkout/forms/AddressForm/graphql/QUERY_GET_COUNTRY.graphql';
import '@corratech/checkout/forms/AddressForm/addressForm.less';
import 'ModulesPath/Checkout/CheckoutPage.less';
import {
    AuthStore,
    LoaderStore,
    CartStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { LoqateTagAddress } from '../../LoqateTagAddress';
import { LoqateAddressValidatorAuth } from 'ModulesPath/LoqateAddressValidation/LoqateAddressValidatorAuth';

export function AddressForm({
    initialValues,
    onFormSubmit,
    autoSubmitOff,
    authMode,
    setEditingAddress,
    handleSameBillingAndShippingOnChange,
    isShippingAddress,
    invalidShippingAddress,
    setInvalidShippingAddress
}) {
    const [t] = useTranslation();
    const { authState } = useContext(AuthStore);
    const { cartState } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchField, setSearchField] = useState('');
    const [locateValues, setLocateValues] = useState('');
    const [expanded, setExpanded] = useState(false);
    const [expandedPost, setExpandedPost] = useState(false);
    const [expandedAddress, setExpandedAddress] = useState(false);
    const [addressFinder, setAddressFinder] = useState(true);
    const [addressFinalised, setAddressFinalised] = useState(false);
    const [validate, setValidate] = useState(false);
    const [formData, setFormData] = useState(false);

    const formikRef = useRef();

    const options = useGlobalOptions();
    const { storeConfig } = options;

    const useOutsideClicker = ref => {
        const handleClickOutside = () => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpanded(false);
            }
        };

        useEffect(() => {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        });
    };

    const elementRef = useRef(null);
    useOutsideClicker(elementRef);

    const useOutsidePostClicker = ref => {
        const handleClickOutside = () => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpandedPost(false);
            }
        };

        useEffect(() => {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        });
    };

    const elementPostRef = useRef(null);
    useOutsidePostClicker(elementPostRef);

    const useOutsideAddressClicker = ref => {
        const handleClickOutside = () => {
            if (ref.current && !ref.current.contains(event.target)) {
                setExpandedAddress(false);
            }
        };

        useEffect(() => {
            // Bind the event listener
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener('mousedown', handleClickOutside);
            };
        });
    };

    const elementAddressRef = useRef(null);
    useOutsideAddressClicker(elementAddressRef);

    const updateField = e => {
        setSearchTerm(e.target.value);
        if (formikRef.current) {
            if (e.target.name === 'street1') {
                formikRef.current.setFieldValue('street1', e.target.value);
            } else if (e.target.name === 'postcode') {
                formikRef.current.setFieldValue('postcode', e.target.value);
            } else if (e.target.name === 'shipping_address') {
                formikRef.current.setFieldValue(
                    'shipping_address',
                    e.target.value
                );
            }
        }
    };

    const handleFocus = e => {
        if (e.target.name === 'street1') {
            setExpanded(true);
            setSearchField('street1');
        } else if (e.target.name === 'postcode') {
            setExpandedPost(true);
            setSearchField('postcode');
        } else if (e.target.name === 'shipping_address') {
            setExpandedAddress(true);
            setSearchField('shipping_address');
        } else {
            setSearchField('');
        }
    };

    const {
        loading: loadingCountry,
        error: errorCountry,
        data: countryData
    } = useQuery(QUERY_GET_COUNTRY);

    const getRegionOptionsForSelectedCountry = (
        countryData,
        currentCountryCode
    ) => {
        if (countryData && countryData.countries) {
            const currentCountry = countryData.countries.find(
                country => country.id === currentCountryCode
            );

            let items = [];

            if (currentCountry && currentCountry.available_regions) {
                items = [
                    {
                        value: '',
                        label: t('Please select a region, state or province.')
                    }
                ];
                currentCountry.available_regions
                    .sort(function(a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    })
                    .map(region => {
                        items.push({
                            value: region.code,
                            label: region.name
                        });
                    });
            }

            return items;
        } else {
            return {
                value: '',
                label: t('Please select a region, state or province.')
            };
        }
    };

    const formRegExp = {
        alphabets: new RegExp(/^[a-z\-'\s]+$/i),
        street: new RegExp(
            /^[ \w]{3,}([A-Za-z]\.)?([ \w]*\#\d+)?(\r\n| )[ \w]{3,}/
        ),
        city: new RegExp(/^[a-z\-\s]+$/i),
        zip: {
            us: new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
            uk: new RegExp(
                /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/
            )
        },
        phone: new RegExp(/^[0-9]*$/)
    };

    const validationSchema = yup.object().shape({
        firstname: yup
            .string()
            .required(t('First Name is required'))
            .test(
                'alphabets',
                t('First Name must only contain letters'),
                value => {
                    return formRegExp.alphabets.test(value);
                }
            ),
        lastname: yup
            .string()
            .required(t('Last Name is required'))
            .test(
                'alphabets',
                t('Last Name must only contain letters'),
                value => {
                    return formRegExp.alphabets.test(value);
                }
            ),
        street1: yup.string().required(t('Street Address is required')),
        city: yup.string().required(t('City is required')),
        postcode: yup.string().required(t('ZIP/POSTAL CODE is required')),
        countryCode: yup.string().required(t('Country is required')),
        telephone: yup
            .string()
            .required(t('Phone Number is required'))
            .matches(formRegExp.phone, t('Phone number is not valid'))
    });

    const handleChangeData = async (name, value) => {
        await formikRef.current.setFieldValue(name, value);
        formikRef.current.setFieldTouched(name, true);
    };
    const formFieldNames = [
        'shipping_address',
        'countryCode',
        'street1',
        'street2',
        'city',
        'region',
        'postcode',
        'company'
    ];

    useEffect(() => {
        if (locateValues && formikRef.current) {
            formFieldNames.map(name => {
                if (locateValues[name] !== undefined) {
                    handleChangeData(name, locateValues[name]);
                }
            });
        }
    }, [locateValues]);

    const FormValidation = ({ isValid }) => {
        setInvalidShippingAddress(!isValid);
        return null;
    };

    if (loadingCountry) {
        return <LoadingIndicator />;
    }

    return (
        <>
            <Formik
                innerRef={formikRef}
                enableReinitialize={true}
                initialValues={
                    initialValues
                        ? {
                              ...initialValues,
                              company: initialValues.company
                                  ? initialValues.company
                                  : '',
                              region: initialValues.region.code,
                              countryCode: initialValues.country.code,
                              street1: initialValues.street[0],
                              street2: initialValues.street[1]
                                  ? initialValues.street[1]
                                  : '',
                              save_in_address_book: false,
                              shipping_address:
                                  (initialValues.company
                                      ? initialValues.company
                                      : '') +
                                  ' ' +
                                  (initialValues.street[0]
                                      ? initialValues.street[0]
                                      : '') +
                                  ' ' +
                                  (initialValues.street[1]
                                      ? initialValues.street[1]
                                      : '') +
                                  ' ' +
                                  (initialValues.city
                                      ? initialValues.city
                                      : '') +
                                  ' ' +
                                  (initialValues.city ==
                                  initialValues.region.code || !initialValues.region.code
                                      ? ''
                                      : initialValues.region.code) +
                                  ' ' +
                                  (initialValues.postcode
                                      ? initialValues.postcode
                                      : '')
                          }
                        : {
                              firstname: !!authState.user.firstname
                                  ? authState.user.firstname
                                  : '',
                              lastname: !!authState.user.lastname
                                  ? authState.user.lastname
                                  : '',
                              company: '',
                              street1: '',
                              street2: '',
                              city: '',
                              region: '',
                              postcode: '',
                              countryCode: storeConfig.general_country_default
                                  ? storeConfig.general_country_default
                                  : 'GB',
                              telephone: '',
                              save_in_address_book: false
                          }
                }
                onSubmit={values => {
                    // validate address for shipping address only
                    if (autoSubmitOff && isShippingAddress) {
                        setAddressFinalised(prevState => !prevState);
                        setValidate(true);
                        let data = {
                            ...values,
                            street: [values.street1, values.street2]
                        };
                        setFormData(data);
                    } else {
                        onFormSubmit({
                            variables: {
                                ...values,
                                street: [values.street1, values.street2]
                            }
                        });
                    }
                }}
                validationSchema={validationSchema}
            >
                {({ values, submitForm, isValid, dirty, setFieldValue }) => (
                    <>
                        <Form>
                            <CorraField
                                label={t('First Name')}
                                labelText={t(`first_name`)}
                                required={true}
                            >
                                <Field
                                    name="firstname"
                                    component={TextInputFormik}
                                    type="text"
                                    id="first_name"
                                    data-cs-mask
                                />
                            </CorraField>

                            <CorraField
                                label={t('Last Name')}
                                labelText={t(`last_name`)}
                                required={true}
                            >
                                <Field
                                    name="lastname"
                                    component={TextInputFormik}
                                    type="text"
                                    id="last_name"
                                    data-cs-mask
                                />
                            </CorraField>

                            <CorraField label={t('Country')}>
                                <Field
                                    data-cs-mask
                                    name="countryCode"
                                    component={SelectInputFormik}
                                    items={countryData.countries
                                        .sort(function(a, b) {
                                            const textA = a.full_name_locale
                                                ? a.full_name_locale.toUpperCase()
                                                : '';
                                            const textB = b.full_name_locale
                                                ? b.full_name_locale.toUpperCase()
                                                : '';
                                            return textA < textB
                                                ? -1
                                                : textA > textB
                                                ? 1
                                                : 0;
                                        })
                                        .map(country => ({
                                            value: country.id,
                                            label: country.full_name_locale
                                        }))}
                                    handleOnChange={() =>
                                        setFieldValue('region', '')
                                    }
                                />
                            </CorraField>

                            <div
                                className={`${
                                    addressFinder ? '' : 'no-display'
                                }`}
                            >
                                <CorraField
                                    label={t('Address Finder')}
                                    labelText={t(`shipping_address`)}
                                    required={true}
                                >
                                    <div
                                        className={'loqate-autocomplete'}
                                        ref={elementAddressRef}
                                    >
                                        <Field
                                            name="shipping_address"
                                            component={TextInputFormik}
                                            type="text"
                                            placeholder="Start typing your address…"
                                            id="shipping_address"
                                            autoComplete="none"
                                            onFocus={handleFocus}
                                            onChange={updateField}
                                            data-cs-mask
                                        />
                                        {searchTerm !== '' &&
                                            searchField ===
                                                'shipping_address' && (
                                                <div
                                                    className={
                                                        'loqate-autocomplete-address loqate-autocomplete-street'
                                                    }
                                                >
                                                    <LoqateTagAddress
                                                        searchTerm={searchTerm}
                                                        searchFieldName="shipping_address"
                                                        setVisible={
                                                            setExpandedAddress
                                                        }
                                                        visible={
                                                            expandedAddress
                                                        }
                                                        setLocateValues={
                                                            setLocateValues
                                                        }
                                                        setAddressFinder={
                                                            setAddressFinder
                                                        }
                                                        country={
                                                            values.countryCode
                                                        }
                                                    />
                                                </div>
                                            )}
                                        {expandedAddress && (
                                            <div
                                                onClick={() =>
                                                    setAddressFinder(false)
                                                }
                                                className="manual-address"
                                            >
                                                Enter your address manually
                                            </div>
                                        )}
                                    </div>
                                </CorraField>
                            </div>

                            <div
                                onClick={() => setAddressFinder(true)}
                                className={`${
                                    addressFinder
                                        ? 'no-display'
                                        : 'address-finder'
                                }`}
                            >
                                Use our Address Finder
                            </div>

                            <div
                                className={`${
                                    addressFinder ? 'no-display' : ''
                                }`}
                            >
                                <CorraField
                                    label={t('Company')}
                                    labelText={t(`company`)}
                                >
                                    <Field
                                        name="company"
                                        component={TextInputFormik}
                                        type="text"
                                        id="company"
                                        data-cs-mask
                                    />
                                </CorraField>
                                <CorraField
                                    label={t('Street Address')}
                                    labelText={t(`street_address_1`)}
                                    required={true}
                                >
                                    <div
                                        className={`loqate-autocomplete`}
                                        ref={elementRef}
                                    >
                                        <Field
                                            name="street1"
                                            component={TextInputFormik}
                                            type="text"
                                            id="street_address_1"
                                            autoComplete="none"
                                            onFocus={handleFocus}
                                            onChange={updateField}
                                            data-cs-mask
                                        />
                                        {searchTerm !== '' &&
                                            searchField === 'street1' && (
                                                <div
                                                    className={
                                                        'loqate-autocomplete-address loqate-autocomplete-street'
                                                    }
                                                >
                                                    <LoqateTagAddress
                                                        searchTerm={searchTerm}
                                                        setVisible={setExpanded}
                                                        visible={expanded}
                                                        setLocateValues={
                                                            setLocateValues
                                                        }
                                                        country={
                                                            values.countryCode
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </CorraField>

                                <CorraField
                                    label={t(
                                        'Apartment / Suite / Other (optional)'
                                    )}
                                    labelText={t(`street_address_2`)}
                                >
                                    <Field
                                        name="street2"
                                        component={TextInputFormik}
                                        type="text"
                                        id="street_address_2"
                                        data-cs-mask
                                    />
                                </CorraField>

                                <CorraField
                                    label={t('City')}
                                    labelText={t(`city`)}
                                    required={true}
                                >
                                    <Field
                                        name="city"
                                        component={TextInputFormik}
                                        type="text"
                                        id="city"
                                        data-cs-mask
                                    />
                                </CorraField>
                                <div className={'stateZipCodeWrapper'}>
                                    <CorraField
                                        label="State/Province"
                                        labelText={t(`stateProvince`)}
                                    >
                                        {getRegionOptionsForSelectedCountry(
                                            countryData,
                                            values.countryCode
                                        ).length > 0 ? (
                                            <Field
                                                name="region"
                                                component={SelectInputFormik}
                                                items={getRegionOptionsForSelectedCountry(
                                                    countryData,
                                                    values.countryCode
                                                )}
                                                data-cs-mask
                                            />
                                        ) : (
                                            <Field
                                                name="region"
                                                component={TextInputFormik}
                                                type="text"
                                                data-cs-mask
                                            />
                                        )}
                                    </CorraField>

                                    <CorraField
                                        label={t('Zip/Postal Code ')}
                                        labelText={t(`zipPostalCode`)}
                                        required={true}
                                    >
                                        <div
                                            className={'loqate-autocomplete'}
                                            ref={elementPostRef}
                                        >
                                            <Field
                                                name="postcode"
                                                component={TextInputFormik}
                                                type="text"
                                                id="zipPostalCode"
                                                autoComplete="none"
                                                onFocus={handleFocus}
                                                onChange={updateField}
                                                data-cs-mask
                                            />
                                            {searchTerm !== '' &&
                                                searchField === 'postcode' && (
                                                    <div
                                                        className={
                                                            'loqate-autocomplete-address loqate-autocomplete-postcode'
                                                        }
                                                    >
                                                        <LoqateTagAddress
                                                            searchTerm={
                                                                searchTerm
                                                            }
                                                            setVisible={
                                                                setExpandedPost
                                                            }
                                                            visible={
                                                                expandedPost
                                                            }
                                                            setLocateValues={
                                                                setLocateValues
                                                            }
                                                            country={
                                                                values.countryCode
                                                            }
                                                        />
                                                    </div>
                                                )}
                                        </div>
                                    </CorraField>
                                </div>
                            </div>

                            <div className="phone-field-wrapper">
                                <CorraField
                                    label={t('Phone Number')}
                                    labelText={t(`phoneNumber`)}
                                    required={true}
                                >
                                    <Field
                                        name="telephone"
                                        component={TextInputFormik}
                                        type="text"
                                        id="phoneNumber"
                                        data-cs-mask
                                        onChange={(e) => {
                                            const value = e.target.value.trim() === '' ? '' : e.target.value.replace(/\s+/g, '');
                                            setFieldValue('telephone', value);
                                        }}
                                    />
                                    <div className={'wrapping-svg'}>
                                        <OverlayTrigger
                                            placement="top-start"
                                            overlay={props => (
                                                <Tooltip
                                                    {...props}
                                                    id={`tooltip-account-info`}
                                                    show={props.show.toString()}
                                                >
                                                    {t(
                                                        'For delivery questions.'
                                                    )}
                                                </Tooltip>
                                            )}
                                        >
                                            <Button
                                                variant="success"
                                                className="tooltip-btn"
                                            >
                                                <Info size={16} />
                                            </Button>
                                        </OverlayTrigger>
                                    </div>
                                </CorraField>
                            </div>
                            {authMode && (
                                <div className={'customCheck'}>
                                    <CorraField
                                        isChecked={
                                            values['save_in_address_book']
                                        }
                                        labelText={'save_in_address_book'}
                                        label={t('Save in Address Book')}
                                    >
                                        <Field
                                            name="save_in_address_book"
                                            component={TextInputFormik}
                                            type="checkbox"
                                            id="save_in_address_book"
                                            data-cs-mask
                                        />
                                    </CorraField>
                                </div>
                            )}

                            {autoSubmitOff && (
                                <div className="button-container">
                                    {!!setEditingAddress && cartState.cart.billing_address &&  (
                                        <div className={'step-btn-block'}>
                                            <Button
                                                variant="link"
                                                onClick={() => {
                                                    if (handleSameBillingAndShippingOnChange) {
                                                        handleSameBillingAndShippingOnChange();
                                                    } else {
                                                        setEditingAddress(false);
                                                    }
                                                }}
                                            >
                                                {t(`Cancel`)}
                                            </Button>
                                        </div>
                                    )}
                                    <div className={'step-btn-block'}>
                                        <Button
                                            size="lg"
                                            variant="primary"
                                            type="submit"
                                        >
                                            {t(`Update`)}
                                        </Button>
                                    </div>
                                </div>
                            )}
                            <FormValidation isValid={isValid} />
                        </Form>
                        {!autoSubmitOff && (
                            <AutoSubmit
                                submitForm={submitForm}
                                timeToDebounce={1000}
                                dirty={dirty}
                                values={values}
                                isValid={isValid}
                            />
                        )}
                    </>
                )}
            </Formik>
            {validate && (
                <LoqateAddressValidatorAuth
                    addressFinalised={addressFinalised}
                    formData={formData}
                    onFormSubmit={onFormSubmit}
                />
            )}
        </>
    );
}
