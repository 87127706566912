import { formatPrice } from '@corratech/tag-manager';

function PDPEvents(tagData) {
    const {
        category,
        name,
        sku,
        price,
        currencyCode,
        categoryPath,
        brand,
        thumbnail,
        bloomreach_attributes,
        variants,
        bottle_size,
        __typename
    } = tagData;

    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(window.location.search);
    const urlBottleSize = parseInt(urlParams.get('bottle_size'), 10) || null;
    const selectedBottleSize = urlBottleSize || bottle_size || null;
    const cleanUrl = urlBottleSize
        ? `${url.origin}${url.pathname}?bottle_size=${selectedBottleSize}`
        : `${url.origin}${url.pathname}`;

    // Determine the variant and sale price
    let variantId = __typename === 'SimpleProduct' ? sku : '';
    let salePrice = formatPrice(price);
    let originalPrice = formatPrice(
        tagData?.price_range?.minimum_price?.regular_price?.value || price
    );
    let variantPrice;
    let variantOriginalPrice;
    let rangeProduct = bloomreach_attributes?.range_product ?? '',
        productFunction = bloomreach_attributes?.function ?? '',
        parent = bloomreach_attributes?.parent ?? '',
        salesType = bloomreach_attributes?.sales_type ?? '',
        sizeProduct = bloomreach_attributes?.size_product ?? '',
        marketingTag = bloomreach_attributes?.marketing_tag ?? '';

    if (variants && selectedBottleSize) {
        const matchingVariant = variants.find(variant =>
            variant.attributes.some(
                attribute =>
                    attribute.code === 'bottle_size' &&
                    attribute.value_index === selectedBottleSize
            )
        );

        if (matchingVariant) {
            variantId = matchingVariant.product.sku;
            variantPrice =
                matchingVariant.product?.price_range?.minimum_price?.final_price
                    ?.value;
            if (variantPrice) {
                salePrice = formatPrice(variantPrice);
            }
            variantOriginalPrice =
                matchingVariant.product?.price_range?.minimum_price
                    ?.regular_price?.value;
            if (variantOriginalPrice) {
                originalPrice = formatPrice(variantOriginalPrice);
            }

            rangeProduct =
                matchingVariant.product?.bloomreach_attributes?.range_product ??
                '';
            productFunction =
                matchingVariant.product?.bloomreach_attributes?.function ?? '';
            parent =
                matchingVariant.product?.bloomreach_attributes?.parent ?? '';
            salesType =
                matchingVariant.product?.bloomreach_attributes?.sales_type ??
                '';
            sizeProduct =
                matchingVariant.product?.bloomreach_attributes?.size_product ??
                '';
            marketingTag =
                matchingVariant.product?.bloomreach_attributes?.marketing_tag ??
                '';
        }
    }

    return {
        event: 'product',
        ecommerce: {
            detail: {
                actionField: {
                    list: !!category ? `Category - ${category}` : ''
                },
                products: [
                    {
                        name,
                        id: sku,
                        price: formatPrice(price),
                        currency_code: currencyCode,
                        category: categoryPath,
                        brand,
                        thumbnailUrl: thumbnail.url ?? '',
                        category_level_1:
                            bloomreach_attributes?.category_level_1 ?? '',
                        category_level_2:
                            bloomreach_attributes?.category_level_2 ?? '',
                        category_level_3:
                            bloomreach_attributes?.category_level_3 ?? '',
                        categories_path:
                            bloomreach_attributes?.categories_path ?? '',
                        categories_ids: bloomreach_attributes?.categories_ids
                            ? JSON.parse(bloomreach_attributes?.categories_ids)
                            : [],
                        category_id: bloomreach_attributes?.category_id ?? '',
                        range_product: rangeProduct,
                        function: productFunction,
                        parent: parent,
                        sales_type: salesType,
                        size_product: sizeProduct,
                        marketing_tag: marketingTag,
                        product_id: sku,
                        variant_id: variantId,
                        sale_price: salePrice,
                        original_price: originalPrice,
                        location: cleanUrl
                    }
                ]
            }
        },
        _clear: true
    };
}

export { PDPEvents };
