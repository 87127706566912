/*
 * Cannot use useScript hook since script has to be
 * loaded on demnand. prevents using inside an hook
 * @param url
 * @param attributes
 */
const injectScipt = () => {
    const url = "https://x.klarnacdn.net/kp/lib/v1/api.js";

    let script = document.querySelector(`script[src="${url}"]`);

    //if (!script) {
    script = document.createElement('script');
    script.src = url;
    script.defer = true;
    script.setAttribute('async', true);

    document.body.appendChild(script);

    script.onerror = () => {
        if (script) console.warn('Error loading signifyd script');
    };
};

/**
 * Remove the script using some identifier
 * @param url
 */
const removeScript = identifier => {
    if (!identifier) return;
    let script = document.querySelector(`script[${identifier}]`);

    if (script) {
        document.head.removeChild(script);
    }
};


const isKlarnaEnabled = (storeConfig) => {
    const klarnaEnabledStoresArray = storeConfig.klarna_enabled_in_stores !== ''
        ? storeConfig.klarna_enabled_in_stores.split(',') : [];
    return storeConfig.klarna_enabled
        && klarnaEnabledStoresArray.includes(window.STORE_CODE);
}

const getKlarnaLogo = () => {
    return 'https://x.klarnacdn.net/payment-method/assets/badges/generic/black/klarna.svg';
}

const getKlarnaTabTitle = () => {
    return 'Klarna';
}

const getKlarnaDisabledMessage = (klarnaConfig) => {
    return `Klarna can’t be used for the purchase of gift cards or on orders with a total of ${klarnaConfig.currencySymbol}${klarnaConfig.maximumOrderAmount} or more.`
}

export const klarnaHelper = {
    isKlarnaEnabled,
    injectScipt,
    removeScript,
    getKlarnaLogo,
    getKlarnaTabTitle,
    getKlarnaDisabledMessage
};
