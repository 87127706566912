import React, {
    useContext,
    useEffect,
    useState,
    Fragment,
    Suspense
} from 'react';
import {
    AuthStore,
    CartStore,
    LoaderStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useQuery } from 'react-apollo';
import setBraintreePaymentMethod from '@corratech/checkout/PaymentMethods/BraintreePayment/setBraintreePaymentMethod.graphql';
import setPaymentMethodOnCart from '@corratech/checkout/graphql/setPaymentMethodOnCart.graphql';
import '@corratech/checkout/PaymentMethods/PaymentMethodForm.less';
import customerPaymentMethodsQuery from '@corratech/checkout/PaymentMethods/SavedPaymentMethods/getCustomerPaymentTokens.graphql';
import getPaymentMethods from '@corratech/checkout/PaymentMethods/getPaymentMethods.graphql';
import { Accordion, Button, Card } from 'react-bootstrap';
import { PaypalExpress } from '@corratech/paypal-express';

import { StoreCredit } from '@corratech/checkout/StoreCredit/StoreCredit';
import { FreeCheckout } from '@corratech/checkout/PaymentMethods/NoPayment/FreeCheckout';
import { OtherPaymentOptions } from '@corratech/checkout/PaymentMethods/NoPayment/OtherPaymentOptions';
import { useDataLayerAction } from '@corratech/tag-manager';
import { PrivacyPolicy } from 'ModulesPath/Checkout/PrivacyPolicy';
import { klarnaHelper } from '@corratech/klarna-payment/src/utils/helper';
import { isOrderGroovePresent } from 'UtilPath/orderGroove';
import { KlarnaIcon, PayPalIcon, ApplePayIcon } from "@corratech/cart/src/PaymentIcons";
import { ApplePayButton } from 'ModulesPath/BrainTreeApplePay';

/**
 * Lazy load payment methods to exclude from the bundle if not used
 * @type {React.LazyExoticComponent<React.ComponentType<any>>}
 */
const BraintreePayment = React.lazy(() =>
    import('./BraintreePayment/BraintreePayment')
);
const AuthCreditCardForm = React.lazy(() =>
    import('./SavedPaymentMethods/AuthCreditCardForm')
);
const AuthorizeCIM = React.lazy(() =>
    import('@corratech/checkout/PaymentMethods/AuthorizeCIM/AuthorizeCIM')
);
const KlarnaPayment = React.lazy(() =>
    import('@corratech/klarna-payment/src/KlarnaPayment')
);

export const TabbedPaymentMethodsForm = props => {
    const { dataLayerAction, activePaymentMethod, setShowPaymentForm, setIsSubscriptionPaymentError } = props;
    const { authState } = useContext(AuthStore);
    const { cartState, dispatch: cartDispatch } = useContext(CartStore);
    const [activeTab, setActiveTab] = useState(activePaymentMethod || '');
    const [activeKey, setActiveKey] = useState();
    const canMakeApplePayPayment =
        window.ApplePaySession && ApplePaySession.canMakePayments();

    const globalOptions = useGlobalOptions();

    //Payment failure
    const [isPaymentFailureError, setPaymentFailureError] = useState(false);

    let selectedPaymentMethod = null;

    const { data: paymentMethodsData } = useQuery(getPaymentMethods, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            cartDispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    const [t] = useTranslation();

    const isPaymentMethodAvailable = methodCode => {
        return (
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1
        );
    };

    const isOGProductPresent = isOrderGroovePresent(cartState);

    useEffect(() => {
        if (
            !activePaymentMethod &&
            paymentMethodsData &&
            paymentMethodsData.cart.available_payment_methods.length
        ) {
            setActiveTab(
                paymentMethodsData.cart.available_payment_methods[0].code
            );
        }
    }, [paymentMethodsData]);

    /*useEffect(() => {
        if (activeTab) {
            dataLayerAction({
                type: 'CHECKOUT_OPTION',
                data: {
                    step: 2,
                    option: activeTab
                }
            });
        }
    }, [activeTab]);*/

    if (isPaymentMethodAvailable('free')) {
        return (
            <>
                {cartState.cart.authenticated && <StoreCredit />}
                <FreeCheckout
                    dataLayerAction={dataLayerAction}
                    invalidShippingAddress={props.invalidShippingAddress}
                    setInvalidShippingAddress={props.setInvalidShippingAddress}
                />
            </>
        );
    }

    const PaymentMethod = ({
           eventKey,
           icon: Icon,
           label,
           content: Content,
           isOpen,
           setOpenKey
       }) => (
        <Card
            as="li"
            bsPrefix={`payment-method-item ${isOpen ? "payment-method-item-open" : ""}`}>
            <Card.Header>
                <Accordion.Toggle
                    as={Button}
                    variant="link"
                    bsPrefix="payment-method-toggle"
                    eventKey={eventKey}
                    aria-label={`Open ${label} for Payment`}
                    onClick={() => setOpenKey(eventKey)}>
                    {Icon && <span className="svg-wrapper"><Icon /></span>} {label}
                </Accordion.Toggle>
            </Card.Header>
            <Accordion.Collapse eventKey={eventKey}>
                <Card.Body bsPrefix="payment-method-inner">
                    {Content && <Content />}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    );


    /**
     * authnetcim will have one more method of payment
     * Ie; ACH. would require the prop in future.
     */
    return (
        <div className={'payment-method-wrapper'}>
            <StoreCredit />
            <h2 className={'step-title payment-method-heading'}>
                {t('Payment Methods')}
            </h2>

            <Accordion
                as="ul"
                bsPrefix="payment-methods"
                activeKey={activeKey}
                onSelect={(key) => setActiveKey(key)}
            >
                {/* Authenticated Credit Card Form */}
                {(isPaymentMethodAvailable('braintree') ||
                        isPaymentMethodAvailable('braintree_cc_vault')) &&
                    cartState.cart.authenticated ? (
                        <Suspense fallback={''}>
                            <AuthCreditCardForm
                                activeKey={activeKey}
                                isPaymentFailureError={isPaymentFailureError}
                                setPaymentFailureError={setPaymentFailureError}
                                dataLayerAction={dataLayerAction}
                                invalidShippingAddress={props.invalidShippingAddress}
                                setInvalidShippingAddress={props.setInvalidShippingAddress}
                            />
                        </Suspense>
                    ): (
                        <Suspense fallback={''}>
                            <BraintreePayment
                                activeKey={activeKey}
                                setIsSubscriptionPaymentError={setIsSubscriptionPaymentError}
                                setShowPaymentForm={setShowPaymentForm}
                                isPaymentFailureError={
                                    isPaymentFailureError
                                }
                                setPaymentFailureError={
                                    setPaymentFailureError
                                }
                                dataLayerAction={dataLayerAction}
                                invalidShippingAddress={
                                    props.invalidShippingAddress
                                }
                                setInvalidShippingAddress={
                                    props.setInvalidShippingAddress
                                }
                            />
                        </Suspense>
                )}
                {isPaymentMethodAvailable('braintree_applepay') && canMakeApplePayPayment && !isOGProductPresent && (
                    <BraintreePayment
                        activeKey={activeKey}
                        setIsSubscriptionPaymentError={setIsSubscriptionPaymentError}
                        setShowPaymentForm={setShowPaymentForm}
                        isPaymentFailureError={
                            isPaymentFailureError
                        }
                        setPaymentFailureError={
                            setPaymentFailureError
                        }
                        dataLayerAction={dataLayerAction}
                        invalidShippingAddress={
                            props.invalidShippingAddress
                        }
                        setInvalidShippingAddress={
                            props.setInvalidShippingAddress
                        }
                        isApplePayOnly={true}
                    />
                )}
                {/* Render Other Payment Methods */}
                {[
                    {
                        key: "authnetcim",
                        label: "Authorize.net CIM",
                        content: () => (
                            <AuthorizeCIM
                                method={'authnetcim'}
                                dataLayerAction={dataLayerAction}
                                invalidShippingAddress={props.invalidShippingAddress}
                                setInvalidShippingAddress={props.setInvalidShippingAddress}
                            />
                        ),
                        condition: isPaymentMethodAvailable('authnetcim'),
                    },
                    {
                        key: "checkmo",
                        label: "Cash On Delivery",
                        content: () => (
                            <OtherPaymentOptions
                                dataLayerAction={dataLayerAction}
                                invalidShippingAddress={props.invalidShippingAddress}
                                setInvalidShippingAddress={props.setInvalidShippingAddress}
                            />
                        ),
                        condition: isPaymentMethodAvailable('checkmo') || isPaymentMethodAvailable('cashondelivery'),
                    },
                ].map(
                    ({ key, label, icon, content, condition }) =>
                        condition && (
                            <PaymentMethod
                                key={key}
                                eventKey={key}
                                label={label}
                                icon={icon}
                                content={content}
                                isOpen={activeKey === key}
                                setOpenKey={setActiveKey}
                            />
                        )
                )}

                {isPaymentMethodAvailable('paypal_express') && !isOGProductPresent && (
                        <Card
                            as="li"
                            bsPrefix={`payment-method-item ${
                                activeKey === "paypal" ? "payment-method-item-open" : ""
                            }`}>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    bsPrefix="payment-method-toggle"
                                    eventKey="paypal"
                                    aria-label="Open paypal for Payment"
                                    onClick={() => setActiveKey("paypal")}
                                >
                                    <span className="svg-wrapper">
                                        <PayPalIcon />
                                    </span> PayPal
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="paypal">
                                <Card.Body bsPrefix="payment-method-inner">
                                    <PaypalExpress
                                        isIncontext={globalOptions.storeConfig.paypal_incontext_mode}
                                        merchantId={globalOptions.storeConfig.paypal_account_id}
                                        isSandbox={globalOptions.storeConfig.paypal_account_is_sandbox_mode}
                                        returnUrl={'checkout/paypal/return'}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                )}

                {klarnaHelper.isKlarnaEnabled(globalOptions.storeConfig) &&
                    !isOGProductPresent && (
                        <Card
                            as="li"
                            bsPrefix={`payment-method-item ${
                                activeKey === "klarna" ? "payment-method-item-open" : ""
                            }`}>
                            <Card.Header>
                                <Accordion.Toggle
                                    as={Button}
                                    variant="link"
                                    bsPrefix="payment-method-toggle"
                                    eventKey="klarna"
                                    aria-label="Open Klarna for Payment"
                                    onClick={() => setActiveKey("klarna")}
                                >
                                    <KlarnaIcon /> {klarnaHelper.getKlarnaTabTitle()}
                                </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="klarna">
                                <Card.Body bsPrefix="payment-method-inner">
                                    <KlarnaPayment
                                        isPaymentFailureError={isPaymentFailureError}
                                        setPaymentFailureError={setPaymentFailureError}
                                        dataLayerAction={dataLayerAction}
                                        PrivacyPolicy={PrivacyPolicy}
                                        invalidShippingAddress={props.invalidShippingAddress}
                                        setInvalidShippingAddress={props.setInvalidShippingAddress}
                                    />
                                </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                    )}
            </Accordion>
        </div>
    );
};

// Old form
export const PaymentMethodForms = props => {
    const { dataLayerAction } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const { authState } = useContext(AuthStore);
    const [t] = useTranslation();

    const LoadingIndicator = useContext(LoaderStore);

    const [setPaymentMethod, { data: dataAfterSet }] = useMutation(
        setPaymentMethodOnCart
    );

    const [shouldShowBraintree, setShouldShowBraintree] = useState(false);

    const [
        getSavedPaymentMethods,
        { data: customerPaymentTokens, loading: tokensLoading }
    ] = useLazyQuery(customerPaymentMethodsQuery, {
        fetchPolicy: 'network-only'
    });

    const [setBraintreePayment, { data: braintreeSetData }] = useMutation(
        setBraintreePaymentMethod,
        {
            variables: {
                cartId: cartState.cartId
            }
        }
    );

    useEffect(() => {
        if (dataAfterSet) {
            const updatedCart = cartState.cart;
            updatedCart.selected_payment_method =
                dataAfterSet.setPaymentMethodOnCart.cart.selected_payment_method;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    }, [dataAfterSet]);

    useEffect(() => {
        if (braintreeSetData) {
            const updatedCart = cartState.cart;
            updatedCart.selected_payment_method =
                braintreeSetData.setPaymentMethodOnCart.cart.selected_payment_method;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    }, [braintreeSetData]);

    //get saved payments if customer is logged in
    useEffect(() => {
        if (authState.token && authState.token !== '') {
            getSavedPaymentMethods();
        }
    }, [authState.token]);

    const selectMethod = methodCode => {
        if (methodCode !== 'braintree') {
            setPaymentMethod({
                variables: {
                    cartId: cartState.cartId,
                    methodCode
                }
            });
        } else if (methodCode === 'braintree') {
            setShouldShowBraintree(true);
        }
    };

    if (tokensLoading) return <LoadingIndicator />;
    else if (!shouldShowBraintree) {
        return (
            <div className={'payment-method-form'}>
                <h2 className={'step-title payment-method-heading'}>
                    {t('Payment Methods')}
                </h2>
                {cartState.cart.available_payment_methods.map(method => (
                    <div
                        className={'payment-method'}
                        key={method.title + ';' + method.code}
                    >
                        <input
                            type="radio"
                            name="payment_method"
                            value={`${method.title} ';' ${method.code}` || ''}
                            onChange={() => selectMethod(method.code)}
                            checked={
                                (cartState.cart.selected_payment_method &&
                                    cartState.cart.selected_payment_method
                                        .code === method.code) ||
                                false
                            }
                        />
                        <span className={'payment-method-title'}>
                            {method.title}
                        </span>
                    </div>
                ))}
                {customerPaymentTokens &&
                customerPaymentTokens.customerPaymentTokens.items.length > 0
                    ? cartState.cart.available_payment_methods.map(method => {
                          return (
                              <div
                                  className={'payment-method'}
                                  key={
                                      method.type +
                                      ';' +
                                      JSON.parse(method.details).maskedCC
                                  }
                              >
                                  <input type="radio" name="payment_method" />
                                  <span className={'payment-method-title'}>
                                      {method.type +
                                          ': ' +
                                          JSON.parse(method.details).maskedCC}
                                  </span>
                              </div>
                          );
                      })
                    : null}
            </div>
        );
    } else {
        return (
            <div className={'payment-method-form'}>
                <h2 className={'step-title payment-method-heading'}>
                    {t('Payment Methods')}
                </h2>
                <button
                    className={'white-button'}
                    onClick={() => setShouldShowBraintree(false)}
                >
                    {t('Use a different method')}
                </button>
                <BraintreePayment
                    setIsSubscriptionPaymentError={setIsSubscriptionPaymentError}
                    setShowPaymentForm={setShowPaymentForm}
                    isPaymentFailureError={isPaymentFailureError}
                    setPaymentFailureError={setPaymentFailureError}
                    setPaymentMethod={setBraintreePayment}
                    dataLayerAction={dataLayerAction}
                />
            </div>
        );
    }
};

TabbedPaymentMethodsForm.defaultProps = {
    activePaymentMethod: 'braintree'
};
