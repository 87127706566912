import React, { useContext, useState, Fragment, useCallback } from 'react';
import { object, string } from 'prop-types';
import { useMutation } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import applyCouponToCart from './Queries/applyCouponToCart.graphql';
import removeCouponFromCart from './Queries/removeCouponFromCart.graphql';
import { CartStore } from '@corratech/context-provider';
import { Field, TextInput } from '@corratech/form-components';
import { CollapsibleContainer } from '@corratech/collapsible-container';
import { Button } from 'react-bootstrap';
import { Check as CheckIcon, AlertCircle as ErrorIcon } from 'react-feather';
import { FreeGifts } from '@corratech/free-gifts';
import { Form } from 'informed';
require('./CouponForm.less');

export const CouponForm = props => {
    const {
        css,
        className,
        applyCouponToCartGraphql,
        removeCouponFromCartGraphql,
        promoTitle,
        ErrorIcon,
        successMsg1,
        successMsg2,
        showFreeGifts,
        removeItemFromCartGraphql
    } = props;
    const [t] = useTranslation();

    const [couponCode, setCouponCode] = useState('');
    const [couponError, setCouponError] = useState(false);
    const [couponProductError, setCouponProductError] = useState(false);

    const { cartState, dispatch } = useContext(CartStore);
    const [isToggle, setIsToggle] = useState(false);

    const [applyCoupon, { loading }] = useMutation(applyCouponToCartGraphql, {
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.applyCouponToCart.cart
            });
            setIsToggle(true);
            //Reset Promo error on valid execution
            setCouponError(false);
            setCouponProductError(false);
        },
        onError: res => {
            if (
                res?.graphQLErrors[0]?.extensions?.category === 'graphql-coupon'
            ) {
                setCouponProductError(true);
            }
            setCouponError(true);
        }
    });

    const [removeCoupon, { loading: removeLoading }] = useMutation(
        removeCouponFromCartGraphql,
        {
            variables: {
                cartId: cartState.cartId,
                isSignedIn: !!cartState.cart.authenticated
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: res.removeCouponFromCart.cart
                });
                setIsToggle(false);
                //Reset promo from coupon state after remove
                setCouponCode('');
                setCouponProductError(false);
                setCouponError(false);
            },
            onError: res => {
                //Handle Error here
                console.error(res);
            }
        }
    );

    const applyCouponEvent = useCallback(() => {
        if (!couponCode) return null;
        if (couponCode.trim()) {
            applyCoupon({
                variables: {
                    couponCode
                }
            });
        } else {
            setCouponError(true);
        }
    }, [couponCode]);

    const handleChange = event => {
        setCouponError(false);
        setCouponProductError(false);
        const value = event.target.value.replace(/\s+/g, '');
        setCouponCode(value);
    };

    const promoBlock = () => (
        <Fragment>
            {cartState.cart.applied_coupons &&
            cartState.cart.applied_coupons[0] ? (
                <div className={'applied-coupon-block'}>
                    <div className={'applied-coupon-wrapper'}>
                        <div className={'applied-coupon'} data-cs-mask>
                            <CheckIcon size={24} color={'#89ac3c'} />
                            {`${t(successMsg1)}  "${
                                cartState.cart.applied_coupons[0].code
                            }" ${t(successMsg2)}`}
                        </div>
                        <div className={'remove-coupon'}>
                            <Button
                                variant="link"
                                onClick={() => removeCoupon()}
                                disabled={removeLoading}
                            >
                                {removeLoading ? t(`Removing`) : t(`Remove`)}
                            </Button>
                        </div>
                    </div>
                    {showFreeGifts && (
                        <FreeGifts
                            ErrorIcon={ErrorIcon}
                            removeItemFromCartGraphql={
                                removeItemFromCartGraphql
                            }
                        />
                    )}
                </div>
            ) : (
                <Form onSubmit={!loading && applyCouponEvent}>
                    <div className={'promo-text-filed'}>
                        <Field label={t(promoTitle)} required={true}>
                            <TextInput
                                type="text"
                                field="coupon-code"
                                fieldState={{
                                    value: couponCode,
                                    maskedValue: couponCode
                                }}
                                autoComplete="off"
                                placeholder={t(`Enter promo code`)}
                                data-cs-mask
                                onChange={handleChange}
                            />
                        </Field>
                        <Button
                            onClick={applyCouponEvent}
                            disabled={loading || couponCode === ''}
                            size="lg"
                            variant="primary"
                        >
                            {loading ? t('Adding') : t('Add')}
                        </Button>
                        {couponError ? (
                            <div className={'coupon-error'}>
                                <span className={'message'}>
                                    {ErrorIcon}
                                    {couponCode
                                        ? (couponProductError
                                              ? t(`Code`)
                                              : t(`Sorry, Promo code`)) +
                                          ' "' +
                                          couponCode +
                                          '" ' +
                                          (couponProductError
                                              ? t(
                                                    `is now out of stock and has not been added to your order`
                                                )
                                              : t('is not valid'))
                                        : t(`Sorry, Promo code is not valid`)}
                                </span>
                            </div>
                        ) : (
                            <span className={'note'}>
                                {t('1 promo code may be applied per order.')}
                            </span>
                        )}
                    </div>
                </Form>
            )}
        </Fragment>
    );

    return (
        <div
            className={'coupon-form ' + (props.className || '')}
            css={props.css}
        >
            <div className={'discount-coupon-form accordion-wrapper'}>
                <CollapsibleContainer
                    title={t(promoTitle)}
                    children={promoBlock()}
                    toggleAccordion={isToggle}
                />
            </div>
        </div>
    );
};

CouponForm.propTypes = {
    className: string,
    css: object,
    promoTitle: string,
    successMsg1: string,
    successMsg2: string
};

CouponForm.defaultProps = {
    applyCouponToCartGraphql: applyCouponToCart,
    removeCouponFromCartGraphql: removeCouponFromCart,
    promoTitle: 'Add a Promo code',
    successMsg1: 'Promo Code',
    successMsg2: 'successfully applied!',
    ErrorIcon: <ErrorIcon size={24} color={'#B70020'} />
};
