import { useState, useEffect } from 'react';
import { useGlobalOptions } from '@corratech/context-provider';

export const useIsMobile = () => {
    const { viewport } = useGlobalOptions(); // Отримуємо конфігурацію глобально
    const breakpoint = viewport.mobile; // Витягуємо потрібний брейкпойнт

    const [isMobile, setIsMobile] = useState(() =>
        window.matchMedia(`(max-width: ${breakpoint}px)`).matches
    );

    useEffect(() => {
        const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);

        const handleChange = (event) => {
            setIsMobile(event.matches);
        };

        mediaQuery.addEventListener('change', handleChange);
        return () => mediaQuery.removeEventListener('change', handleChange);
    }, [breakpoint]);

    return isMobile;
};